import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface PageHeaderProps {
  children?: React.ReactElement;
  data: { label: string; path: string }[];
}

const PageHeader: FC<PageHeaderProps> = ({ children, data }): ReactElement => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        mb: "12px",
        color: "rgba(0,0,0,0.3)",
        py: "10px",
      }}
    >
      {data.map(({ label, path }, i) => (
        <React.Fragment key={path}>
          <Link
            to={path}
            style={{ textDecoration: "none", color: "rgba(0,0,0,0.7)" }}
          >
            <Typography variant="h5">{t(label)}</Typography>
          </Link>
          {i < data.length - 1 ? (
            <Typography mx="12px" variant="h5">
              /
            </Typography>
          ) : (
            ""
          )}
        </React.Fragment>
      ))}
      <Box sx={{ flexGrow: 1 }}></Box>
      <>{children}</>
    </Box>
  );
};

export default PageHeader;
