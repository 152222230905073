import Box from "@mui/material/Box";
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { getDisplayDateTime } from "../utils/getDisplayDate";
import Card from "@mui/material/Card";
import TooltipIconButton from "../components/TooltipIconButton";
import LineChart from "../components/charts/LineChart";
import { getChartDatasets } from "../components/charts/getChartData";
import TableWrapper from "../components/table/TableWrapper";
import { t } from "i18next";
import { getPeriod } from "../utils/getPeriod";
import { useHttp } from "../hooks/useHttpClient";
import { useParams } from "react-router-dom";
import NonPeriodicDeviceStatePanel from "./NonPeriodicStatePanel";
import PowerOffRoundedIcon from "@mui/icons-material/PowerOffRounded";
import PowerRoundedIcon from "@mui/icons-material/PowerRounded";
import MeetingRoomRoundedIcon from "@mui/icons-material/MeetingRoomRounded";
import NoMeetingRoomRoundedIcon from "@mui/icons-material/NoMeetingRoomRounded";
import Button from "@mui/material/Button";
import FullScreenPreview from "../components/FullScreenPreview";
import { daysOfWeek } from "../utils/consts";

const getLabel = (type?: string, index: number = 0) => {
  const chartLabels: Record<string, string[]> = {
    "open&close": [t("open")],
    switch: [t("on")],
  };
  if (type) {
    return chartLabels[type][index];
  }
  return "--";
};

interface NonPeriodicDeviceReportWrapperProps {
  deviceType: string;
}

const headers = ["status", "duration", "time"];

const NonPeriodicDeviceReportWrapper: FC<
  NonPeriodicDeviceReportWrapperProps
> = ({ deviceType }): ReactElement => {
  const HttpGet = useHttp();
  const HttpUpdateSwitchState = useHttp();
  const { installationId } = useParams();
  const { t } = useTranslation();

  const offset = useRef(0);
  const limit = useRef(5);

  const getData = useCallback(async () => {
    HttpGet.newRequest(
      `/report/nonPeriodicDevice/${installationId}?limit=${limit.current}&offset=${offset.current}`
    );
  }, []);

  const handlePagination = (_offset: number, _limit: number) => {
    offset.current = _offset;
    limit.current = _limit;
    getData();
  };

  const handleSwitchStateUpdate = async (newState: number) => {
    try {
      await HttpUpdateSwitchState.newRequest(
        `/installations/switchStateUpdate/${installationId}`,
        {
          method: "PATCH",
          body: { newState },
          successMessage: "valueSucessfullyUpdated",
          throwError: true,
        }
      );
      offset.current = 0;
      getData();
    } catch {}
  };

  const getStatusLabel = (value: number) => {
    if (deviceType === "switch") {
      return !value ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <PowerOffRoundedIcon color="secondary" /> {t("off")}{" "}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <PowerRoundedIcon color="primary" /> {t("on")}
        </Box>
      );
    }
    return !value ? (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <NoMeetingRoomRoundedIcon color="secondary" sx={{ mr: "4px" }} />{" "}
        {t("closed")}{" "}
      </Box>
    ) : (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <MeetingRoomRoundedIcon color="primary" sx={{ mr: "4px" }} />{" "}
        {t("open")}
      </Box>
    );
  };

  const keys = useMemo(
    () => [
      {
        key: "value1",
        render: getStatusLabel,
      },
      {
        key: "duration",
        render: (value: string) => getPeriod(+value),
      },
      {
        key: "created",
        render: (value: string) => getDisplayDateTime(value),
      },
    ],
    [HttpGet.items]
  );

  const HttpGetChartData = useHttp();

  const [fullScreen, setFullScreen] = useState(false);
  const [chartGranularity, setChartGranularity] = useState("hour");
  const [chartDataType, setChartDataType] = useState("count");

  const days = [
    "",
    ...daysOfWeek
  ];

  const getChartData = useCallback(async () => {
    HttpGetChartData.newRequest(
      `/report/nonPeriodicDevice/chartData/${installationId}?type=${chartDataType}&granularity=${chartGranularity}`
    );
  }, [chartGranularity, chartDataType]);

  let chartLabels = HttpGetChartData.items.map((item) =>
    t(days[item?.granularity])
  );

  if (chartGranularity === "hour") {
    chartLabels = HttpGetChartData.items.map((item) => `${item?.granularity}h`);
  }

  // let chartLabel = "weeklyOpenCount";
  // if (chartGranularity === "hour") {
  //   if (chartDataType === "count") {
  //     chartLabel = "dailyOpenCount";
  //   } else {
  //     chartLabel = "dailyAverageDuration";
  //   }
  // } else if (chartGranularity === "day") {
  //   if (chartDataType === "duration") {
  //     chartLabel = "weeklyAverageDuration";
  //   }
  // }

  let chartLabel = "average";
  if (chartDataType === "count") {
    chartLabel = "byActiveCount";
  }

  const chartConfig = {
    labels: chartLabels,
    datasets: getChartDatasets(
      deviceType,
      [HttpGetChartData.items.map((item) => item?.value)],
      t(chartLabel)
    ),
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getChartData();
  }, [getChartData]);

  const maxChartValue = Math.max(
    ...HttpGetChartData.items.map((item) => item.value)
  );

  const cutomYLabel = (value: number, index: any, ticks: any): string => {
    if (chartDataType === "duration") {
      return `${value?.toFixed(1)}%`;
    }
    return `${value?.toFixed(1)}`;
  };

  return (
    <Grid
      container
      sx={(theme) => ({
        [theme.breakpoints.down("lg")]: {
          flexDirection: "column-reverse",
        },
      })}
    >
      <Grid item xs={12} lg={7}>
        <Card
          elevation={3}
          sx={(theme) => ({
            p: "20px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
            width: "100%",
            minHeight: "340px",
            // mt: "40px",
            [theme.breakpoints.up("lg")]: {
              // ml: "35px",
            },
          })}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              mb: "10px",
            }}
          >
            <Button
              onClick={() => setChartGranularity("hour")}
              sx={{ p: "0px 10px" }}
              variant={chartGranularity === "hour" ? "contained" : "outlined"}
            >
              {t("daily")}
            </Button>
            <Button
              onClick={() => setChartGranularity("day")}
              sx={{ p: "0px 10px", mx: "22px" }}
              variant={chartGranularity === "day" ? "contained" : "outlined"}
            >
              {t("weekly")}
            </Button>

            <Box flexGrow={1}></Box>
            <TooltipIconButton
              title="fullScreen"
              onClick={() => setFullScreen(true)}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              mb: "10px",
            }}
          >
            <Button
              onClick={() => setChartDataType("count")}
              sx={{ p: "0px 10px" }}
              variant={chartDataType === "count" ? "contained" : "outlined"}
            >
              {t("byActiveCount")}
            </Button>
            <Button
              onClick={() => setChartDataType("duration")}
              sx={{ mx: "22px", p: "0px 10px" }}
              variant={chartDataType === "duration" ? "contained" : "outlined"}
            >
              {t("duration")}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              width: "100%",
              height: "",
            }}
          >
            <LineChart
              data={chartConfig}
              yStepSize={
                chartDataType === "count"
                  ? Math.floor(maxChartValue * 0.08)
                  : maxChartValue * 0.08
              }
              loading={HttpGetChartData.loading}
              suggestedMax={maxChartValue + maxChartValue * 0.11}
              suggestedMin={0}
              cutomYLabel={cutomYLabel}
            />
          </Box>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        lg={5}
        sx={(theme) => ({
          [theme.breakpoints.up("lg")]: {
            pl: "35px",
          },
        })}
      >
        <NonPeriodicDeviceStatePanel
          data={HttpGet.item}
          deviceType={deviceType}
          handleSwitchStateUpdate={handleSwitchStateUpdate}
          switchUpdateLoading={HttpUpdateSwitchState.loading || HttpGet.loading}
        />
        <TableWrapper
          data={HttpGet.items}
          headers={headers}
          keys={keys}
          page={offset.current}
          loading={HttpGet.loading}
          pageRows={limit.current}
          skeletonCount={limit.current}
          totalCount={HttpGet.totalCount}
          handlePagination={handlePagination}
          handleRowSelect={() => null}
        />
      </Grid>
      {fullScreen && (
        <FullScreenPreview onClose={() => setFullScreen(false)}>
          <LineChart
            data={chartConfig}
            yStepSize={
              chartDataType === "count"
                ? Math.floor(maxChartValue * 0.04)
                : maxChartValue * 0.04
            }
            loading={HttpGetChartData.loading}
            suggestedMax={maxChartValue + maxChartValue * 0.1}
            suggestedMin={0}
            handleChartMargines={() => {}}
          />
        </FullScreenPreview>
      )}
    </Grid>
  );
};

export default NonPeriodicDeviceReportWrapper;
