import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import React, { FC, ReactElement } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHttp } from "../../hooks/useHttpClient";
import { t } from "i18next";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";

interface NewDeviceFormProps {
  getData: () => Promise<void>;
  handleDialog: () => void;
}

const availableOptions = [
  { value: "switch", label: "switch" },
  { value: "open&close", label: "open&close" },
  { value: "temp&humid", label: "temp&humid" },
  { value: "pressure", label: "pressure" },
  { value: "temp", label: "temp" },
  { value: "humid", label: "humid" },
];

const formFields: {
  name: "deviceType";
  rules: Record<any, any>;
  props: any;
}[] = [
  {
    name: "deviceType",
    rules: {
      required: "requiredField",
    },
    props: {
      select: true,
      options: availableOptions,
    },
  },
];

const NewDeviceForm: FC<NewDeviceFormProps> = ({
  getData,
  handleDialog,
}): ReactElement => {
  const HttpCreate = useHttp();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      deviceType: "",
    },
  });

  const handleLocationCreation = async (values: any) => {
    const { deviceType } = values;
    await HttpCreate.newRequest(`/admin/devices`, {
      method: "POST",
      body: {
        type: deviceType,
      },
      successMessage: "deviceSucessfullyCreated",
    });
    handleDialog();
    getData();
  };

  return (
    <Box sx={{ width: "400px", p: "30px" }}>
      <form onSubmit={handleSubmit(handleLocationCreation)}>
        {formFields.map(({ name, rules, props }) => (
          <Box key={name} pb="24px">
            <Controller
              name={name as any}
              rules={rules}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  {...props}
                  fullWidth
                  label={t(name)}
                  error={!!errors[name]}
                  helperText={t(errors[name]?.message as string, {
                    value: errors[name],
                  })}
                >
                  {props.options?.map(
                    ({ value, label }: { value: number; label: string }) => {
                      const itemValue = value;
                      return (
                        <MenuItem key={itemValue} value={itemValue}>
                          {t(label)}
                        </MenuItem>
                      );
                    }
                  )}
                </TextField>
              )}
            />
          </Box>
        ))}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" sx={{ mt: "20px" }} type="submit">
            {HttpCreate.loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t("save")
            )}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default NewDeviceForm;
