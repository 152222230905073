import { FC, ReactElement } from "react";
import { Outlet } from "react-router-dom";
import RouteGuard from "../providers/guards/RouteGuard";

interface SectionWrapperProps {}

const AdminSectionWrapper: FC<SectionWrapperProps> = (): ReactElement => {
  return (
    <RouteGuard authorizedRoles={["superAdmin"]}>
      <Outlet />
    </RouteGuard>
  );
};

export default AdminSectionWrapper;
