import { useSocketInit } from "./socket";
import LoadingOverlayer from "../../components/Loading";

interface Props {
  children: React.ReactElement;
}

const SocketProvider = ({ children }: Props) => {
  // const { initialized } = useSocketInit("http://stefan.pikado.net");

  // if (!initialized) {
  //   return <LoadingOverlayer />;
  // }
  return <>{children}</>;
};

export default SocketProvider;
