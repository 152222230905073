import React from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./source/en.json";
import sr from "./source/sr.json";
import fr from "./source/fr.json";
import de from "./source/de.json";

const setUp = () => {
  i18n.use(initReactI18next).init(
    {
      fallbackLng: "sr",
      resources: {
        en: {
          translation: en,
        },
        sr: {
          translation: sr,
        },
        fr: {
          translation: fr,
        },
        de: {
          translation: de,
        },
      },
      lng: localStorage.getItem("systemLanguage") || "sr",
      // debug: false,
      // backend: {
      //   loadPath: sourcePath,
      // },
    },
    (err) => {
      /*eslint-disable-next-line no-console*/
      if (err) return console.error("something went wrong loading", err);
    }
  );
};

interface Props {
  children: React.ReactElement;
}

export const LocalizationProvider = ({
  children,
}: Props): React.ReactElement => {
  setUp();
  return <>{children}</>;
};
