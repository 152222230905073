import { FC, ReactElement } from "react";
import { useAuth } from "../AuthProvider";

interface ContentGuardProps {
  children: ReactElement;
  authorizedRoles?: string[];
}

const ContentGuard: FC<ContentGuardProps> = ({
  children,
  authorizedRoles,
}): ReactElement => {
  const { assignedRoles } = useAuth();
  console.log("ROLES: ", assignedRoles);
  if (!authorizedRoles) {
    return children;
  }
  const accessCheck = authorizedRoles.some((role) =>
    assignedRoles?.includes(role)
  );

  if (accessCheck) {
    return children;
  }
  return <></>;
};

export default ContentGuard;
