import React, { FC, ReactElement } from "react";
import ChartWrapper from "./ChartWrapper";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

interface LineChartProps {
  data: any;
  loading: boolean;
  suggestedMax?: number;
  suggestedMin?: number;
  yStepSize?: number;
  handleChartMargines?: (hidden1: boolean, hidden2: boolean) => void;
  cutomYLabel?: (value: any, index: any, ticks: any) => string;
}

const LineChart: FC<LineChartProps> = ({
  data,
  loading,
  suggestedMax,
  suggestedMin,
  yStepSize = 0.2,
  handleChartMargines,
  cutomYLabel,
}): ReactElement => {
  const { t } = useTranslation();
  const options = {
    responsive: true,
    layout: {
      padding: 0,
    },
    plugins: {
      legend: {
        display: true,
        onClick: (e: any, r: any, legend: any) => {
          const chartInstance = legend.chart;
          const datasetIndex = r.datasetIndex;
          const meta = chartInstance.getDatasetMeta(datasetIndex);
          meta.hidden = !meta.hidden;
          if (handleChartMargines) {
            const meta1 = chartInstance.getDatasetMeta(0);
            const meta2 = chartInstance.getDatasetMeta(1);
            handleChartMargines(meta1.hidden, meta2.hidden);
          }
          chartInstance.update();
        },
      },
      tooltip: {
        // enabled: false,
        // usePointStyle: false
        // callbacks: {
        //   label: (val: any): string => {
        //     console.log("WAEDRGH: ", val);
        //     return "df" + val;
        //   },
        // },
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
    scales: {
      x: {
        ticks: {
          // callback: function (val: any, index: number): string {
          //   // Hide every 2nd tick label
          //   return index % 2 === 0 ? "we" : "df";
          // },
        },
      },
      y: {
        display: true,
        scaleLabel: {
          display: true,
          labelString: "Value",
        },
        suggestedMin: suggestedMin,
        suggestedMax: suggestedMax,
        ticks: {
          stepSize: yStepSize,
          callback:
            cutomYLabel ?? ((value: any, index: any, ticks: any) => `${value?.toFixed(1)}`),
        },
      },
    },
  };

  data = {
    ...data,
    datasets: data.datasets.map((item: any) => ({
      ...item,
      label: t(item.label),
    })),
  };

  return (
    <ChartWrapper loading={loading}>
      <Line options={options} data={data} />
    </ChartWrapper>
  );
};

export default LineChart;
