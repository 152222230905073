import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import "react-circular-progressbar/dist/styles.css";
import Grid from "@mui/material/Grid";
import { getChartLabel } from "../utils/getDisplayDate";
import FilterPanel from "../components/FilterPanel";
import { t } from "i18next";
import TempHumidScreen from "./report-screens/TempHumid";
import { useParams } from "react-router-dom";
import PressureScreen from "./report-screens/Pressure";
import { useHttp } from "../hooks/useHttpClient";

const getLabel = (type?: string, index: number = 0) => {
  const chartLabels: Record<string, string[]> = {
    "temp&humid": [t("temperature"), t("humidity")],
    temp: [t("temperature")],
    humid: [t("humidity")],
    pressure: [t("pressure")],
  };
  if (type) {
    return chartLabels[type][index];
  }
  return "--";
};

interface PeriodicDeviceReportWrapperProps {
  deviceType: string;
}

const PeriodicDeviceReportWrapper: FC<PeriodicDeviceReportWrapperProps> = ({
  deviceType,
}): ReactElement => {
  const HttpGet = useHttp();
  const { installationId } = useParams();

  const [data, setData] = useState<any[]>([]);
  const [granularity, setGranularity] = useState("hour");

  let generateCSVData = (): any[] => {
    if (data) {
      return data.map(({ value1, value2, created }) => {
        if (!value2) {
          return {
            [getLabel(deviceType)]: value1,
            [t("time")]: getChartLabel(created, granularity),
          };
        }
        return {
          [getLabel(deviceType)]: value1,
          [getLabel(deviceType, 1)]: value2,
          [t("time")]: getChartLabel(created, granularity),
        };
      });
    }
    return [];
  };

  const getData = useCallback(async (query: string) => {
    // HttpGet.newRequest(`/readings/count/${installationId}${query}`);
    HttpGet.newRequest(`/report/periodicDevice/${installationId}${query}`);
  }, []);

  useEffect(() => {
    if (!HttpGet.loading) {
      setData(HttpGet.items);
    }
  }, [HttpGet.loading]);

  const handleGranularity = (value: string) => {
    setGranularity(value);
  };

  const dashboard: Record<string, React.ReactElement> = {
    "temp&humid": (
      <TempHumidScreen
        granularity={granularity}
        data={data}
        loading={HttpGet.loading}
      />
    ),
    pressure: (
      <PressureScreen
        granularity={granularity}
        data={data}
        loading={HttpGet.loading}
      />
    ),
  };
  return (
    <Grid container sx={{}}>
      <Grid xs={8} item>
        {dashboard[deviceType] || <>Not Found</>}
      </Grid>
      <Grid xs={4} item>
        <FilterPanel
          granularity={granularity}
          setGranularity={handleGranularity}
          generateCSVData={generateCSVData}
          getData={getData}
        />
      </Grid>
    </Grid>
  );
};

export default PeriodicDeviceReportWrapper;
