import Box from "@mui/material/Box";
import React, { FC, ReactElement } from "react";
import CircularProgressbar from "./CircularProgressbar";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

interface ValuePrevievProps {
  label: string;
  type: string;
  value: number;
}

const ValuePreviev: FC<ValuePrevievProps> = ({
  label,
  type,
  value,
}): ReactElement => {
  const { t } = useTranslation();

  const displayValue = value ? value.toFixed(1) : "";

  const config: Record<string, React.ReactElement> = {
    temperature: (
      <CircularProgressbar
        label={t(label)}
        value={100}
        text={`${displayValue}°C`}
        color="red"
      />
    ),
    humidity: (
      <CircularProgressbar
        label={t(label)}
        value={+value}
        text={`${displayValue}%`}
      />
    ),
    pressure: (
      <Box textAlign="center" minWidth="200px">
        <Typography variant="h5" mb="20px" align="center">
          {t(label)}
        </Typography>

        <Typography variant="h4" align="center" color="rgba(6, 191, 49, 1)">
          {`${displayValue}mbar`}
        </Typography>
      </Box>
    ),
  };
  return <>{config[type]}</>;
};

export default ValuePreviev;
