import { t } from "i18next";

const hour = 60 * 60;

export const getPeriod = (seconds: number): string => {
  if (!seconds) {
    return "--";
  } else if (Math.floor(seconds / (60 * 60 * 24)) > 0) {
    return `${Math.floor(seconds / (60 * 60 * 24))} ${t("d")}`;
  } else if (Math.floor(seconds / (60 * 60)) > 0) {
    return `${Math.floor(seconds / (60 * 60))} ${t("h")}`;
  } else if (Math.floor(seconds / 60) > 0) {
    return `${Math.floor(seconds / 60)} ${t("m")}`;
  }
  return `${seconds} ${t("s")}`;
};
