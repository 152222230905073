import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { FC, ReactElement } from "react";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import DeleteSweepRoundedIcon from "@mui/icons-material/DeleteSweepRounded";
import Zoom from "@mui/material/Zoom";

import { useTranslation } from "react-i18next";

interface TooltipIconButtonProps {
  title: string;
  color?: "primary" | "info" | "secondary";
  disabledMessage?: string;
  onClick: () => void;
}

const icons: Record<string, React.ReactElement> = {
  delete: <DeleteSweepRoundedIcon />,
  fullScreen: <FullscreenIcon />,
  exitFullScreen: <FullscreenExitIcon />,
};

const TooltipIconButton: FC<TooltipIconButtonProps> = ({
  title,
  color,
  disabledMessage,
  onClick,
}): ReactElement => {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={disabledMessage ? t(disabledMessage) : t(title)}
      arrow
      TransitionComponent={Zoom}
      PopperProps={{
        sx: { zIndex: 10000 },
      }}
    >
      <span>
        <IconButton
          disabled={!!disabledMessage}
          color={color}
          onClick={onClick}
        >
          {icons[title]}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default TooltipIconButton;
