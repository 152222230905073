import React, { FC, ReactElement, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import SelectElement from "./Select";
import { TimeClock } from "@mui/x-date-pickers/TimeClock";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import SCVExport from "../utils/CSV";
import Card from "@mui/material/Card";
import dayjs, { Dayjs } from "dayjs";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

interface FilterPanelProps {
  granularity: string;
  setGranularity: (value: string) => void;
  generateCSVData: () => any[];
  getData: (query: string, granularity: string) => Promise<void>;
}

const FilterPanel: FC<FilterPanelProps> = ({
  granularity,
  setGranularity,
  generateCSVData,
  getData,
}): ReactElement => {
  const [selectedTime, setSelectedTime] = useState(dayjs(new Date()));
  const { t } = useTranslation();

  const handleTimeSelect = (e: Dayjs | null, variant: "date" | "time") => {
    if (e) {
      if (variant === "time") {
        setSelectedTime((prev) => prev.set("hour", e.hour()));
      } else {
        // setSelectedTime((prev) => {
        //   prev.set("year", e.year());
        //   prev.set("month", e.month());
        //   prev.set("date", e.date());
        //   return prev;
        // });
        setSelectedTime(e);
      }
    }
  };

  const handleGet = () => {
    let from = dayjs.utc(selectedTime).format("YYYY-MM-DD HH");
    let to = selectedTime
      .set("hour", selectedTime.hour() + 1)
      .utc()
      .format("YYYY-MM-DD HH");
    if (granularity === "day") {
      to = selectedTime
        .set("day", selectedTime.day() + 1)
        .utc()
        .format("YYYY-MM-DD HH");
    } else if (granularity === "week") {
      to = selectedTime
        .set("day", selectedTime.day() + 7)
        .utc()
        .format("YYYY-MM-DD HH");
    } else if (granularity === "month") {
      from = dayjs.utc(selectedTime).format("YYYY-MM-DD");
      to = selectedTime
        .set("month", selectedTime.month() + 1)
        .utc()
        .format("YYYY-MM-DD");
    }

    const query = `?granularity=${granularity}&from=${from}&to=${to}`;
    getData(query, granularity);
  };

  useEffect(() => {
    handleGet();
  }, []);

  return (
    <Card
      elevation={3}
      sx={{
        p: "30px",
        width: "100%",
        // height: `100%`,
        ml: "35px",
        // display: "flex",
        // justifyContent: "flex-start",
        // alignItems: "center",
      }}
    >
      <Grid container spacing={4}>
        <Grid
          item
          xs={5}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <SelectElement
            onChange={(e) => setGranularity(e.target.value)}
            data={{ label: t("period") }}
            value={granularity}
            options={[
              { value: "hour", label: t("hour") },
              { value: "day", label: t("day") },
              { value: "week", label: t("daysCount", { count: 7 }) },
              { value: "month", label: t("month") },
            ]}
          />
          <DatePicker
            label={t("date")}
            disableFuture
            value={selectedTime}
            onChange={(e) => handleTimeSelect(e, "date")}
            sx={{ my: "30px" }}
          />
          <Button onClick={handleGet} variant="contained">
            {t("load")}
          </Button>
        </Grid>
        <Grid
          item
          xs={7}
          sx={{ height: "fit-content", blockSize: "fit-content" }}
        >
          <Box sx={{ height: "fit-content" }}>
            <TimeClock
              ampm={false}
              sx={{}}
              views={["hours"]}
              value={selectedTime}
              onChange={(e) => handleTimeSelect(e as Dayjs, "time")}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: "40px",
          }}
        >
          {" "}
          <SCVExport data={generateCSVData()} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default FilterPanel;
