import Box from "@mui/material/Box";
import React, {
  FC,
  ReactElement,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TableWrapper from "../../../components/table/TableWrapper";
import { useTranslation } from "react-i18next";
import TooltipIconButton from "../../../components/TooltipIconButton";
import { getDisplayDateTime } from "../../../utils/getDisplayDate";
import { getShorterText } from "../../../utils/getShorterText";
import { useNavigate, useParams } from "react-router-dom";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import TooltipIcon from "../../../components/TooltipIcon";
import { useHttp } from "../../../hooks/useHttpClient";
import CreationForm from "./CreationForm";
import WiFi from "../../../components/WiFi";

interface SensorInstallationScreenProps {}

const headers = [
  "name",
  "description",
  "sensorType",
  "status",
  "WiFi",
  "created",
  "",
];

const SensorInstallationScreen: FC<
  SensorInstallationScreenProps
> = (): ReactElement => {
  const { locationId } = useParams();
  const offset = useRef(0);
  const limit = useRef(10);
  const [confirmationId, setConfirmationId] = useState<string | null>(null);
  const [confirmationDeviceId, setConfirmationDeviceId] = useState<
    string | null
  >(null);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const HttpGet = useHttp();
  const HttpGetDevices = useHttp();
  const HttpDelete = useHttp();

  const getData = async () => {
    HttpGet.newRequest(
      `/installations/location/${locationId}?limit=${limit.current}&offset=${offset.current}&installationType=sensor`
    );
  };

  const getAvailableDevices = async () => {
    HttpGetDevices.newRequest(`/devices/available?deviceType=sensor`);
  };

  const removeItem = async () => {
    setConfirmationId(null);
    await HttpDelete.newRequest(
      `/installations/${confirmationId}/${confirmationDeviceId}`,
      {
        method: "DELETE",
        successMessage: "installationSucessfullyRemoved",
      }
    );
    getData();
    getAvailableDevices();
  };

  useEffect(() => {
    getData();
    getAvailableDevices();
  }, []);

  const keys = useMemo(
    () => [
      {
        key: "name",
      },

      {
        key: "description",
        render: (value: string) => (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {getShorterText(value, 15)}{" "}
            <TooltipIcon sx={{ ml: "4px" }} title={value} />
          </Box>
        ),
      },
      {
        key: "type",
        render: (value: string) => {
          if (value) {
            return t(value);
          }
          return "--";
        },
      },
      {
        key: "status",
        render: (value: number) => {
          if (!value) {
            return (
              <ReportRoundedIcon
                sx={{ mr: "16px" }}
                color="error"
                fontSize="medium"
              />
            );
          }
          return (
            <CheckCircleRoundedIcon
              sx={{ mr: "16px" }}
              color="success"
              fontSize="medium"
            />
          );
        },
      },
      {
        key: "wifiStrength",
        render: (value: number) => <WiFi strength={value} />,
      },
      {
        key: "created",
        render: (value: string) => getDisplayDateTime(value),
      },
      {
        key: "_id",
        render: (value: string, data: any) => (
          <TooltipIconButton
            title="delete"
            color="secondary"
            // disabledMessage="Ne moze!"
            onClick={() => {
              setConfirmationId(value);
              setConfirmationDeviceId(data.deviceId);
            }}
          />
        ),
        preventClick: true,
      },
    ],
    []
  );

  const handlePagination = (_offset: number, _limit: number) => {
    offset.current = _offset;
    limit.current = _limit;
    getData();
  };

  const handleRowSelect = (id: string) => {
    navigate(`${id}`);
  };

  return (
    <Grid container sx={{}} columnSpacing={2}>
      <Grid item xs={9} sx={{ my: "10px", pr: "20px" }}>
        <TableWrapper
          data={HttpGet.items}
          headers={headers}
          keys={keys}
          page={offset.current}
          loading={HttpGet.loading}
          pageRows={limit.current}
          skeletonCount={5}
          totalCount={HttpGet.totalCount}
          handlePagination={handlePagination}
          handleRowSelect={handleRowSelect}
        />
      </Grid>
      <Grid item xs={3} sx={{}}>
        <Card sx={{ p: "20px", mt: "10px" }} elevation={3}>
          <Typography mb="26px" variant="h6">
            {t("createNewInstalation")}
          </Typography>
          <CreationForm
            devices={HttpGetDevices.items}
            getData={getData}
            getAvailableDevices={getAvailableDevices}
          />
        </Card>
      </Grid>

      {!!confirmationId && (
        <ConfirmationModal
          title={`${t("sureDeleteItem")} ${t("installation")}?`}
          confirmationId={confirmationId}
          onClose={() => setConfirmationId(null)}
          onConfirm={removeItem}
        />
      )}
    </Grid>
  );
};

export default SensorInstallationScreen;
