import React, {
  FC,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Grid } from "@mui/material";
import { getDisplayDateTime } from "../../utils/getDisplayDate";
import TableWrapper from "../../components/table/TableWrapper";
import Switch from "@mui/material/Switch";
import { useParams } from "react-router-dom";
import ConfirmationModal from "../../components/ConfirmationModal";
import AlertLogs from "./AlertLogs";
import { useTranslation } from "react-i18next";
import { getAlertDisplayType } from "./getAlertLabels";
import TooltipIconButton from "../../components/TooltipIconButton";
import { useHttp } from "../../hooks/useHttpClient";
import CreationForm from "./CreationForm";

interface AlertsProps {
  type: string;
  deviceId: number;
}

const headers = ["condition", "email", "active", "created", ""];

const Alerts: FC<AlertsProps> = ({ type, deviceId }): ReactElement => {
  const { installationId } = useParams();
  const offset = useRef(0);
  const limit = useRef(10);
  const { t } = useTranslation();

  const [confirmationId, setConfirmationId] = useState<string | null>(null);

  const HttpGet = useHttp();
  const HttpDelete = useHttp();
  const HttpActivityUpdate = useHttp();

  const getAlerts = async () => {
    HttpGet.newRequest(
      `/alerts/${installationId}?limit=${limit.current}&offset=${offset.current}`
    );
  };

  const handleAlertActivity = async (value: number, _id: number) => {
    await HttpActivityUpdate.newRequest(`/alerts/activity/${_id}`, {
      method: "PATCH",
      body: { active: value === 1 ? 0 : 1 },
      successMessage: "alertSucessfullyUpdated",
    });
    getAlerts();
  };

  const removeAlert = async (_id: string) => {
    setConfirmationId(null);
    await HttpDelete.newRequest(`/alerts/${_id}`, {
      method: "DELETE",
      successMessage: "alertSucessfullyRemoved",
    });
    getAlerts();
  };

  useEffect(() => {
    getAlerts();
  }, []);

  const keys = useMemo(
    () => [
      {
        key: "state",
        render: (value: any, data: any) => {
          return getAlertDisplayType(type, value, data.value);
        },
      },

      {
        key: "email",
      },
      {
        key: "active",
        render: (value: number, data: any) => (
          <Switch
            {...{ inputProps: { "aria-label": "Switch demo" } }}
            checked={!!value}
            onClick={() => handleAlertActivity(value, data._id)}
          />
        ),
      },
      {
        key: "created",
        render: (value: string) => getDisplayDateTime(value),
      },
      {
        key: "_id",
        render: (value: string) => (
          <TooltipIconButton
            title="delete"
            color="secondary"
            onClick={() => setConfirmationId(value)}
          />
        ),
      },
    ],
    []
  );

  const handlePagination = (_offset: number, _limit: number) => {
    offset.current = _offset;
    limit.current = _limit;
    getAlerts();
  };
  const valueDisabled = type === "open&close" || type === "switch";
  return (
    <Grid container sx={{}} columnSpacing={2}>
      <Grid item xs={9} sx={{ pr: "40px" }}>
        <CreationForm
          type={type}
          deviceId={deviceId}
          getAlerts={getAlerts}
          valueDisabled={valueDisabled}
        />
        <TableWrapper
          data={HttpGet.items}
          headers={headers}
          keys={keys}
          page={offset.current}
          loading={HttpGet.loading}
          pageRows={limit.current}
          skeletonCount={5}
          totalCount={HttpGet.totalCount}
          handlePagination={handlePagination}
          handleRowSelect={() => null}
          hover={false}
        />
      </Grid>
      <Grid item xs={3}>
        <AlertLogs />
      </Grid>
      {!!confirmationId && (
        <ConfirmationModal
          title={`${t("sureDeleteItem")} ${t("alert")}?`}
          confirmationId={confirmationId}
          onClose={() => setConfirmationId(null)}
          onConfirm={removeAlert}
        />
      )}
    </Grid>
  );
};

export default Alerts;
