import Box from "@mui/material/Box";
import React, { FC, ReactElement, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { getChartLabel } from "../../utils/getDisplayDate";
import Card from "@mui/material/Card";
import FullScreenPreview from "../../components/FullScreenPreview";
import { Typography } from "@mui/material";
import TooltipIconButton from "../../components/TooltipIconButton";
import LineChart from "../../components/charts/LineChart";
import { getChartDatasets } from "../../components/charts/getChartData";
import ValuePreviev from "../../components/ValuePreview";

interface TempHumidScreenProps {
  data: { value1: number; value2: number; created: string }[];
  loading: boolean;
  granularity: string;
}

const TempHumidScreen: FC<TempHumidScreenProps> = ({
  data,
  loading,
  granularity,
}): ReactElement => {
  const { t } = useTranslation();
  const [fullScreen, setFullScreen] = useState(false);
  const [chartData, setChartData] = useState({
    labels: [] as string[],
    tValues: [] as number[],
    hValues: [] as number[],
  });

  const [graphMargines, setGraphMargines] = useState({
    suggestedMax: 2,
    suggestedMin: 2,
  });

  const handleData = (
    values: { created: string; value1: number; value2: number }[]
  ) => {
    const labels = values.map((item) =>
      getChartLabel(item.created, granularity)
    );
    const tValues = values.map((item) => item.value1);
    const hValues = values.map((item) => item.value2);
    setChartData({
      labels,
      tValues,
      hValues,
    });
  };

  useEffect(() => {
    handleData(data);
  }, [data]);

  useEffect(() => {
    handleChartMargines(false, false);
  }, [chartData]);

  let humidMax = Math.max(...chartData.hValues);
  let humidMin = Math.min(...chartData.hValues);
  let humidAverage = (
    chartData.hValues.reduce((a, b) => +a + +b, 0) / chartData.hValues.length
  ).toFixed(1);
  let tempMax = Math.max(...chartData.tValues);
  let tempMin = Math.min(...chartData.tValues);
  let tempAverage = (
    chartData.tValues.reduce((a, b) => +a + +b, 0) / chartData.tValues.length
  ).toFixed(1);

  const handleChartMargines = (tempHidden: boolean, humidHidden: boolean) => {
    let suggestedMin = [tempMin, humidMin];
    let suggestedMax = [tempMax, humidMax];
    let marginFactor = 2;
    if (!tempHidden && humidHidden) {
      suggestedMin = [tempMin];
      suggestedMax = [tempMax];
    } else if (tempHidden && !humidHidden) {
      suggestedMin = [humidMin];
      suggestedMax = [humidMax];
    } else if (tempHidden && humidHidden) {
      suggestedMin = [2];
      suggestedMax = [6];
    }
    const diff = Math.max(...suggestedMax) - Math.min(...suggestedMin);

    if (diff < 1) {
      marginFactor = 0.2;
    } else if (diff < 2) {
      marginFactor = 0.5;
    } else if (diff < 5) {
      marginFactor = 1;
    }

    setGraphMargines({
      suggestedMin: Math.min(...suggestedMin) - marginFactor,
      suggestedMax: Math.max(...suggestedMax) + marginFactor,
    });
  };

  const chartConfig = {
    labels: chartData.labels,
    datasets: getChartDatasets("temp&humid", [
      chartData.tValues,
      chartData.hValues,
      // chartData.tValues.map(item => item - 5),
      // chartData.hValues,
    ]),
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: `520px`,
          mb: "35px",
          flexWrap: "wrap",
        }}
      >
        <Card
          elevation={3}
          sx={{
            p: "20px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "100%",
            height: `100%`,
          }}
        >
          <LineChart
            data={chartConfig}
            loading={loading}
            suggestedMax={graphMargines.suggestedMax}
            suggestedMin={graphMargines.suggestedMin}
            handleChartMargines={handleChartMargines}
          />
          <Box flexGrow={1}></Box>
          <TooltipIconButton
            title="fullScreen"
            onClick={() => setFullScreen(true)}
          />
        </Card>
      </Box>

      <Card
        elevation={3}
        sx={{
          p: "30px",
          // display: "flex",
          // justifyContent: "flex-start",
          // alignItems: "center",
          width: "100%",
          // height: `100%`,
          mt: "35px",
          //   mb: "35px",
        }}
      >
        <Grid container sx={{ width: "100%" }}>
          <Grid item xs={6}>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={12} sx={{ mb: "36px" }}>
                <Typography variant="h5" align="center">
                  {t("temperature")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                {[
                  { label: "averageValue", value: tempAverage },
                  { label: "maxValue", value: tempMax },
                  { label: "minValue", value: tempMin },
                ].map(({ label, value }) => (
                  <ValuePreviev
                    key={label}
                    label={label}
                    type="temperature"
                    value={+value}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container sx={{ width: "100%" }}>
              <Grid item xs={12} sx={{ mb: "36px" }}>
                <Typography variant="h5" align="center">
                  {t("humidity")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                {[
                  { label: "averageValue", value: humidAverage },
                  { label: "maxValue", value: humidMax },
                  { label: "minValue", value: humidMin },
                ].map(({ label, value }) => (
                  <ValuePreviev
                    key={label}
                    label={label}
                    type="humidity"
                    value={+value}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      {fullScreen && (
        <FullScreenPreview onClose={() => setFullScreen(false)}>
          <LineChart
            data={chartConfig}
            loading={loading}
            suggestedMax={graphMargines.suggestedMax}
            suggestedMin={graphMargines.suggestedMin}
            handleChartMargines={handleChartMargines}
          />
        </FullScreenPreview>
      )}
    </Box>
  );
};

export default TempHumidScreen;
