import "./App.css";
import SocketProvider from "./providers/socket/SocketProvider";
import Layout from "./Layout";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "./providers/SnackbarContext";
import { LocalizationProvider } from "./providers/localization/LocalizationProvider";
import { LocalizationProvider as DateAdapterProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Calendar from "./Calendar";
import SSE from "./SSE";

dayjs.extend(utc);

export interface User {
  id: number;
}
interface Desk {
  _id: number;
  type: number;
  number: number;
}
export interface Service {
  _id: number;
  name: string;
  desks: Desk[];
  price: number;
}
export interface LocationData {
  _id: number;
  name: string;
  city: string;
  address: string;
  code: string;
  services: Service[];
  currency: string;
}

const theme = createTheme({
  palette: {
    mode: "light",
    // background: {
    //   default: "#1b2430",
    // },
    primary: {
      main: "#588157",
    },
    secondary: {
      main: "#e07a5f",
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "0.75rem",
          color: "#fff",
          zIndex: 50000,
          // backgroundColor: "red"
        },
      },
    },
  },

  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
    h4: {
      fontSize: "1.75rem",
      fontWeight: 600,
    },
    h5: {
      fontSize: "1.2rem",
      fontWeight: 600,
    },
    h6: {
      fontSize: "1rem",
    },
    body1: {
      // color: "#c9c8c7",
      fontSize: "0.9rem",
    },
    body2: {
      color: "#787b80",
      fontSize: "0.7rem",
    },
  },
});


function App() {
  return (
    <DateAdapterProvider dateAdapter={AdapterDayjs}>
      <LocalizationProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SocketProvider>
            <SnackbarProvider>
              <Layout />
            </SnackbarProvider>
          </SocketProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </DateAdapterProvider>
  );
}

export default App;
