export const currencies: Record<string, string> = {
  BAM: "KM",
  EUR: "€",
  USD: "$",
  GBP: "£",
  RSD: " RSD",
};
export const daysOfWeek = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday ",
  "friday",
  "saturday",
];
