import Box from "@mui/material/Box";
import React, { FC, ReactElement } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ width: "100%" }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, width: "100%" }}>{children}</Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabsWrapperProps {
  tabs: string[];
  screens?: { condition: boolean; screen: React.ReactElement }[];
  handleTabChange?: (index: number) => void
}

const TabsWrapper: FC<TabsWrapperProps> = ({
  tabs,
  screens,
  handleTabChange
}): ReactElement => {
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if(handleTabChange) {
        handleTabChange(newValue)
    }
  };
  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          mt: "14px",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabs.map((item, i) => (
            <Tab label={t(item)} {...a11yProps(i)} />
          ))}
        </Tabs>
        <Box flexGrow={1}></Box>
      </Box>

      {screens?.map(({ condition, screen }, i) => (
        <CustomTabPanel key={i} value={value} index={i}>
          {condition && screen}
        </CustomTabPanel>
      ))}
    </>
  );
};

export default TabsWrapper;
