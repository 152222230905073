import Box from "@mui/material/Box";
import { useEffect, useRef, useState } from "react";
import TableWrapper from "../../components/table/TableWrapper";
import { getDisplayDateTime } from "../../utils/getDisplayDate";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { t } from "i18next";
import PageHeader from "../../components/PageHeader";
import { useHttp } from "../../hooks/useHttpClient";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AssignDeviceForm from "./AssignDeviceForm";
import WiFi from "../../components/WiFi";

const headers = ["installation", "deviceType", "status", "WiFi", "created"];
const keys = [
  {
    key: "instalationName",
    render: (value: string) => {
      if (value) {
        return t(value);
      }
      return "--";
    },
  },
  {
    key: "type",
    render: (value: string) => {
      if (value) {
        return t(value);
      }
      return "--";
    },
  },
  {
    key: "status",
    render: (value: number) => {
      let color:
        | "success"
        | "default"
        | "primary"
        | "secondary"
        | "error"
        | "info"
        | "warning" = "success";
      if (!value) {
        color = "error";
        return <ReportRoundedIcon sx={{ mr: "16px" }} color={color} />;
      }
      return <CheckCircleRoundedIcon sx={{ mr: "16px" }} color={color} />;
    },
  },
  {
    key: "wifiStrength",
    render: (value: number) => <WiFi strength={value} />,
  },
  {
    key: "created",
    render: (value: string) => getDisplayDateTime(value),
  },
];

const Devices = () => {
  const offset = useRef(0);
  const limit = useRef(10);
  const [dialogOpen, setDialogOpen] = useState(false);
  const HttpGet = useHttp();

  const getData = async () => {
    await HttpGet.newRequest(
      `/devices?limit=${limit.current}&offset=${offset.current}`
    );
  };

  const handlePagination = (_offset: number, _limit: number) => {
    offset.current = _offset;
    limit.current = _limit;
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDialog = () => setDialogOpen((prev) => !prev);

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <PageHeader data={[{ label: "devices", path: "/devices" }]}>
        <Button onClick={handleDialog} variant="outlined">
          {t("addNewDevice")}
        </Button>
      </PageHeader>
      <TableWrapper
        data={HttpGet.items}
        headers={headers}
        keys={keys}
        page={offset.current}
        loading={HttpGet.loading}
        pageRows={limit.current}
        totalCount={HttpGet.totalCount}
        handlePagination={handlePagination}
        handleRowSelect={() => null}
        hover={false}
      />
      {!!dialogOpen && (
        <Dialog open onClose={handleDialog}>
          <AssignDeviceForm getData={getData} handleDialog={handleDialog} />
        </Dialog>
      )}
    </Box>
  );
};

export default Devices;
