import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { FC, ReactElement } from "react";
import {
  CircularProgressbar as Bar,
  buildStyles,
} from "react-circular-progressbar";

interface CircularProgressbarProps {
  label: string;
  description?: string;
  value?: number;
  text: string;
  color?: "red" | "green";
}

const CircularProgressbar: FC<CircularProgressbarProps> = ({
  label,
  description,
  value = 100,
  text = "0",
  color,
}): ReactElement => {
  const style =
    color === "red"
      ? {
          textColor: "rgba(255, 99, 132, 0.9)",
          pathColor: "rgba(255, 99, 132, 0.5)",
          trailColor: "rgba(255, 99, 132, 0.5)",
          textSize: 21,
        }
      : color === "green"
      ? {
          textColor: "rgba(6, 191, 49, 0.9)",
          pathColor: "rgba(6, 191, 49, 0.5)",
          trailColor: "rgba(6, 191, 49, 0.5)",
          textSize: 15,
        }
      : { textSize: 21 };
  return (
    <Box
      style={{
        marginBottom: 0,
        marginLeft: "0px",
        minWidth: "140px",
        width: "140px",
        height: "140px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
      }}
    >
      <Box style={{ marginTop: 0, display: "flex" }}>
        <Box>
          <Typography variant="h6" sx={{ mb: "14px" }} align="center">
            {label}
          </Typography>
          <Typography>{description}</Typography>
        </Box>
      </Box>
      <Box style={{}}>
        <Bar
          strokeWidth={10}
          value={value}
          text={text}
          styles={buildStyles(style)}
        />
      </Box>
    </Box>
  );
};

export default CircularProgressbar;
