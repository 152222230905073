import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";
import { useHttp } from "../../hooks/useHttpClient";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

const formFields: {
  name: "name" | "description";
  rules: Record<any, any>;
  props: any;
}[] = [
  {
    name: "name",
    rules: {
      required: "requiredField",
      minLength: { value: 2, message: t("minLength", { value: 2 }) },
    },
    props: {},
  },
  {
    name: "description",
    rules: {
      required: "requiredField",
      minLength: { value: 8, message: t("minLength", { value: 8 }) },
    },
    props: {
      multiline: true,
      minRows: 3,
    },
  },
];

interface FormProps {
  getData: () => Promise<void>;
}

const CreationForm: React.FC<FormProps> = ({ getData }) => {
  const HttpCreate = useHttp();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const handleLocationCreation = async (values: any) => {
    const { name, description } = values;
    await HttpCreate.newRequest(`/locations`, {
      method: "POST",
      body: {
        clientId: 1000,
        name,
        description,
      },
      successMessage: "locationSucessfullyCreated",
    });
    getData();
  };
  return (
    <form
      onSubmit={handleSubmit(handleLocationCreation)}
    >
      {formFields.map(({ name, rules, props }) => (
        <Box key={name} pb="24px">
          <Controller
            name={name as any}
            rules={rules}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                {...props}
                fullWidth
                label={t(name)}
                error={!!errors[name]}
                helperText={t(errors[name]?.message as string, {
                  value: errors[name],
                })}
              />
            )}
          />
        </Box>
      ))}
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" sx={{ mt: "20px" }} type="submit">
          {HttpCreate.loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            t("save")
          )}
        </Button>
      </Box>
    </form>
  );
};

export default CreationForm;
