import { Typography, useTheme } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  label: string;
  path: string;
  icon: React.ReactElement | null;
}
const NavLink: React.FC<Props> = ({ label, path, icon }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const isActive = location.pathname.startsWith(path);
  console.log("TEST: ", location.pathname, path);
  return (
    <Link
      to={path}
      style={{
        textDecoration: "none",
        fontWeight: 700,
        color: isActive ? theme.palette.primary.main : theme.palette.grey[400],
      }}
    >
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon
            sx={{
              color: isActive
                ? theme.palette.primary.main
                : theme.palette.grey[300],
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText>
            <Typography
              sx={{
                ml: "-10px",
                fontWeight: isActive ? 600 : 400,
              }}
            >
              {t(label)}
            </Typography>
          </ListItemText>
        </ListItemButton>
      </ListItem>
    </Link>
  );
};

export default NavLink;
