import "../loader.css";

const LoadingOverlayer = () => {
  return (
    // <div className="loadingOverlayer">
    //   <div className="lds-ring">
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //   </div>
    // </div>
    <div
      style={{
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        zIndex: "100000",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#fff"
      }}
    >
      <div className="loader" style={{ marginTop: "350px" }}>
        <div className="box box0">
          <div></div>
        </div>
        <div className="box box1">
          <div></div>
        </div>
        <div className="box box2">
          <div></div>
        </div>
        <div className="box box3">
          <div></div>
        </div>
        <div className="box box4">
          <div></div>
        </div>
        <div className="box box5">
          <div></div>
        </div>
        <div className="box box6">
          <div></div>
        </div>
        <div className="box box7">
          <div></div>
        </div>
        <div className="ground">
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingOverlayer;
