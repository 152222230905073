import Box from "@mui/material/Box";
import { FC, useEffect, useRef, ReactElement, useState } from "react";
import TableWrapper from "../../components/table/TableWrapper";
import { getPeriod } from "../../utils/getPeriod";
import { getDisplayDateTime } from "../../utils/getDisplayDate";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { t } from "i18next";
import PageHeader from "../../components/PageHeader";
import { useHttp } from "../../hooks/useHttpClient";
import TooltipIconButton from "../../components/TooltipIconButton";
import ConfirmationModal from "../../components/ConfirmationModal";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CreationForm from "./CreationForm";

const headers = [
  "ID",
  "owner",
  "deviceType",
  "identificationCode",
  "status",
  "created",
  "",
];

interface DevicesScreenProps {}

const DevicesScreen: FC<DevicesScreenProps> = (): ReactElement => {
  const offset = useRef(0);
  const limit = useRef(10);
  const [confirmationId, setConfirmationId] = useState<string | null>(null);
  const [creationDialogOpen, setCreationDialogOpen] = useState(false);

  const HttpGet = useHttp();
  const HttpDelete = useHttp();

  const getData = async () => {
    await HttpGet.newRequest(
      `/admin/devices?limit=${limit.current}&offset=${offset.current}`
    );
  };

  const handlePagination = (_offset: number, _limit: number) => {
    offset.current = _offset;
    limit.current = _limit;
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const removeItem = async () => {
    setConfirmationId(null);
    await HttpDelete.newRequest(`/admin/devices/${confirmationId}`, {
      method: "DELETE",
      successMessage: "deviceSucessfullyRemoved",
    });
    getData();
  };

  const handleDialog = () => setCreationDialogOpen((prev) => !prev);

  const keys = [
    {
      key: "_id",
    },
    {
      key: "clientId",
      render: (value: string) => value ?? "--",
    },
    {
      key: "type",
      render: (value: string) => {
        if (value) {
          return t(value);
        }
        return "--";
      },
    },
    {
      key: "code",
      render: (value: string) => value ?? "--",
    },
    {
      key: "status",
      render: (value: number) => {
        let color:
          | "success"
          | "default"
          | "primary"
          | "secondary"
          | "error"
          | "info"
          | "warning" = "success";
        if (!value) {
          color = "error";
          return (
            <ReportRoundedIcon
              sx={{ mr: "16px" }}
              color={color}
              fontSize="medium"
            />
          );
        }
        return (
          <CheckCircleRoundedIcon
            sx={{ mr: "16px" }}
            color={color}
            fontSize="medium"
          />
        );
      },
    },
    {
      key: "created",
      render: (value: string) => getDisplayDateTime(value),
    },
    {
      key: "_id",
      render: (value: string, data: any) => (
        <TooltipIconButton
          title="delete"
          color="secondary"
          onClick={() => {
            setConfirmationId(value);
          }}
        />
      ),
      preventClick: true,
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <PageHeader data={[{ label: "devices", path: "/admin/devices" }]}>
        <Button onClick={handleDialog} variant="outlined" >
          {t("createNewDevice")}
        </Button>
      </PageHeader>
      <TableWrapper
        data={HttpGet.items}
        headers={headers}
        keys={keys}
        page={offset.current}
        loading={HttpGet.loading}
        pageRows={limit.current}
        totalCount={HttpGet.totalCount}
        handlePagination={handlePagination}
        handleRowSelect={() => null}
        hover={false}
      />
      {!!confirmationId && (
        <ConfirmationModal
          title={`${t("sureDeleteItem")} ${t("installation")}?`}
          confirmationId={confirmationId}
          onClose={() => setConfirmationId(null)}
          onConfirm={removeItem}
        />
      )}
      {!!creationDialogOpen && (
        <Dialog open onClose={handleDialog}>
          <CreationForm getData={getData} handleDialog={handleDialog} />
        </Dialog>
      )}
    </Box>
  );
};

export default DevicesScreen;
