import React, { useRef } from "react";

export const useInfiniteScroll = (
  loadMore: () => void,
  shouldLoadMore: boolean
) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);

  const onScroll = (e: any) => {
    if (shouldLoadMore) {
      if (childRef.current && containerRef.current) {
        const parrentTop = containerRef.current?.getBoundingClientRect().bottom;
        const childTop = childRef.current?.getBoundingClientRect().top;
        if (childTop + 30 < parrentTop) {
          loadMore();
        }
      }
    }
  };

  return {
    containerRef,
    childRef,
    onScroll,
  };
};
