const config: Record<string, any[]> = {
  "temp&humid": [
    {
      label: "temperature",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
      borderColor: "rgb(255, 99, 132)",
    },
    {
      label: "humidity",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
      borderColor: "rgb(53, 162, 235)",
    },
    // {
    //   label: "temperature",
    //   backgroundColor: "rgba(255, 99, 132, 0.5)",
    //   borderColor: "rgb(255, 99, 132)",
    // },
    // {
    //   label: "humidity",
    //   backgroundColor: "rgba(53, 162, 235, 0.5)",
    //   borderColor: "rgb(53, 162, 235)",
    // },
  ],
  temp: [
    {
      label: "temperature",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
      borderColor: "rgb(255, 99, 132)",
    },
  ],
  humid: [
    {
      label: "humidity",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
      borderColor: "rgb(53, 162, 235)",
    },
  ],
  pressure: [
    {
      label: "pressure",
      backgroundColor: "rgba(6, 191, 49, 0.5)",
      borderColor: "rgb(6, 191, 49)",
    },
  ],
  "open&close": [
    {
      label: "weeklyOpenCount",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
      borderColor: "rgb(53, 162, 235)",
    },
  ],
  switch: [
    {
      label: "eee",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
      borderColor: "rgb(53, 162, 235)",
    },
  ],
};

export const getChartDatasets = (type: string, values: number[][], defaultLabel?: string) => {
  return config[type].map(({ label, backgroundColor, borderColor }, i) => ({
    label: defaultLabel ?? label,
    data: values[i].map(item => +item.toFixed(1)),
    borderColor,
    backgroundColor,
    tension: 0.1,
    borderWidth: 2,
  }));
};
