import Box from "@mui/material/Box";
import React, { FC, ReactElement } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  BarController,
} from "chart.js";
import CircularProgress from "@mui/material/CircularProgress";

interface ChartWrapperProps {
  loading: boolean;
  children: React.ReactElement;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  BarController,
  Title,
  Tooltip,
  Legend
);

const ChartWrapper: FC<ChartWrapperProps> = ({
  loading,
  children,
}): ReactElement => {
  return (
    <Box position="relative" width="100%" height="100%">
      {loading && (
        <Box
          width="100%"
          height="100%"
          position="absolute"
          bgcolor="rgba(0,0,0,0.05)"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={64} color="primary" />
        </Box>
      )}
      {children}
    </Box>
  );
};

export default ChartWrapper;
