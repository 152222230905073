import Box from "@mui/material/Box";
import React, { FC, ReactElement, useEffect, useState } from "react";
import { getChartLabel } from "../../utils/getDisplayDate";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FullScreenPreview from "../../components/FullScreenPreview";
import TooltipIconButton from "../../components/TooltipIconButton";
import { getChartDatasets } from "../../components/charts/getChartData";
import LineChart from "../../components/charts/LineChart";
import ValuePreviev from "../../components/ValuePreview";

interface PressureScreenProps {
  data: { value1: number; value2: number; created: string }[];
  loading: boolean;
  granularity: string;
}

const PressureScreen: FC<PressureScreenProps> = ({
  data,
  loading,
  granularity,
}): ReactElement => {
  const [fullScreen, setFullScreen] = useState(false);
  const [chartData, setChartData] = useState({
    labels: [] as string[],
    pValues: [] as number[],
  });

  const handleData = (
    values: { created: string; value1: number; value2: number }[]
  ) => {
    const labels = values.map((item) =>
      getChartLabel(item.created, granularity)
    );
    const pValues = values.map((item) => item.value1 * 100);
    setChartData({
      labels,
      pValues,
    });
  };

  let pMax = Math.max(...chartData.pValues.map((item) => +item));
  let pMin = Math.min(...chartData.pValues.map((item) => +item));
  let pAverage = (
    chartData.pValues.reduce((a, b) => +a + +b, 0) / chartData.pValues.length
  ).toFixed(1);

  let suggestedMin = pMin - 2;
  let suggestedMax = pMax + 2;

  const chartConfig = {
    labels: chartData.labels,
    datasets: getChartDatasets("pressure", [chartData.pValues]),
  };

  useEffect(() => {
    handleData(data);
  }, [data]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: `520px`,
          mb: "35px",
          flexWrap: "wrap",
        }}
      >
        <Card
          elevation={3}
          sx={{
            p: "20px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "100%",
            height: `100%`,
          }}
        >
          <LineChart
            data={chartConfig}
            loading={loading}
            suggestedMax={suggestedMax}
            suggestedMin={suggestedMin}
          />
          <Box flexGrow={1}></Box>
          <TooltipIconButton
            title="fullScreen"
            onClick={() => setFullScreen(true)}
          />
        </Card>
      </Box>

      <Card
        elevation={3}
        sx={{
          p: "30px",
          width: "100%",
          mt: "35px",
        }}
      >
        <Grid container sx={{ width: "100%" }}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              py: "18px",
            }}
          >
            {[
              { label: "averageValue", value: pAverage },
              { label: "maxValue", value: pMax },
              { label: "minValue", value: pMin },
            ].map(({ label, value }) => (
              <ValuePreviev
                key={label}
                label={label}
                type="pressure"
                value={+value}
              />
            ))}
          </Grid>
        </Grid>
      </Card>
      {fullScreen && (
        <FullScreenPreview onClose={() => setFullScreen(false)}>
          <LineChart
            data={chartConfig}
            loading={loading}
            suggestedMax={suggestedMax}
            suggestedMin={suggestedMin}
          />
        </FullScreenPreview>
      )}
    </Box>
  );
};

export default PressureScreen;
