import { useNavigate } from "react-router-dom";
import { ROUTES } from "../Layout";
import { useAuth } from "../providers/AuthProvider";

export const useLogOut = () => {
  const navigate = useNavigate();
  const { dispatch } = useAuth();

  const logOut = () => {
    localStorage.removeItem("accessToken");
    navigate(ROUTES.LOGIN);
    if (dispatch) {
      dispatch({ type: "logOut" });
    }
  };

  return logOut;
};
