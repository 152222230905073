import React, { FC, ReactElement, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useHttp } from "./hooks/useHttpClient";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { t } from "i18next";
import { ROUTES } from "./Layout";
import { useAuth } from "./providers/AuthProvider";
// import BG from "./loginBG.svg";

const formFields: {
  name: "email" | "password";
  rules: Record<any, any>;
  props: any;
}[] = [
  {
    name: "email",
    rules: {
      required: "requiredField",
      validate: (value: string) => /\S+@\S+\.\S+/.test(value) || "invalidEmail",
    },
    props: {},
  },
  {
    name: "password",
    rules: {
      required: "requiredField",
      minLength: { value: 4, message: t("minLength", { value: 4 }) },
    },
    props: {
      type: "password",
    },
  },
];

interface Props {}

const Login: FC<Props> = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dispatch } = useAuth();

  const HttpLogin = useHttp();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      email: "stefan8vucic@gmail.com",
      password: "admin000",
    },
  });

  const onSubmit = async (values: any) => {
    const { email, password } = values;
    reset({ email });
    await HttpLogin.newRequest(`/auth/login`, {
      method: "POST",
      body: {
        password,
        username: email,
      },
    });
  };

  useEffect(() => {
    if (!HttpLogin.loading) {
      if (HttpLogin.item?.accessToken) {
        const initialURL = localStorage.getItem("initialURL");
        localStorage.setItem("accessToken", HttpLogin.item?.accessToken);
        if (dispatch) {
          dispatch({
            type: "initial",
            payload: {
              isAuthenticated: true,
              accessToken: HttpLogin.item?.accessToken,
              user: HttpLogin.item?.user,
              assignedRoles: HttpLogin.item?.user?.assignedRoles,
            },
          });
        }
        navigate(initialURL ?? ROUTES.ROOT);
        localStorage.removeItem("initialURL");
      }
    }
  }, [HttpLogin.loading]);

  return (
    <Box
      sx={{
        // backgroundImage: `url(${BG})`,
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography mb="120px" variant="h3">
        Monitoring Solutions
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {formFields.map(({ name, rules, props }) => (
          <Box key={name} height="90px">
            <Controller
              name={name as any}
              rules={rules}
              control={control}
              render={({ field }) => (
                <TextField
                  {...props}
                  {...field}
                  sx={{ width: "260px" }}
                  label={t(name)}
                  error={!!errors[name]}
                  helperText={t(errors[name]?.message as string, {
                    value: errors[name],
                  })}
                />
              )}
            />
          </Box>
        ))}

        <Button
          sx={{ minWidth: "100px", mt: "25px" }}
          disabled={HttpLogin.loading}
          variant="contained"
          type="submit"
        >
          {HttpLogin.loading ? <CircularProgress size={24} /> : t("login")}
        </Button>
      </form>
    </Box>
  );
};

export default Login;
