import "./App.css";
import BackofficeWrapper from "./back-office/BackofficeWrapper";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Instalations from "./back-office/locations/Locations";
import Devices from "./back-office/devices/Devices";
import AuthProvider from "./providers/AuthProvider";
import Login from "./Login";
import { Box } from "@mui/material";
import DeviceScreen from "./back-office/InstalationScreen";
import InstallationsScreenWrapper from "./back-office/instalations/ScreenWrapper";
import AdminSectionWrapper from "./admin/SectionWrapper";
import NewDeviceForm from "./admin/devices/CreationForm";
import DevicesScreen from "./admin/devices/DevicesScreen";
import ContentGuard from "./providers/guards/ContentGuard";

export const ROUTES = {
  ROOT: "/",
  LOCATIONS: "locations",
  EMPLOYEES: "employees",
  DEVICES: "devices",
  BACK_OFFICE: "/back-office",
  LOGIN: "/login",
  ADMIN: { ROOT: "admin", DEVICES: "devices", CLIENTS: "clients" },
};

function Layout() {
  return (
    <Box
      sx={{
        minHeight: "90vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flexStart",
        alignItems: "center",
      }}
    >
      <Router basename="">
        <AuthProvider>
          <Routes>
            <Route element={<BackofficeWrapper />}>
              <Route index element={<Navigate to={ROUTES.LOCATIONS} />} />
              <Route path={ROUTES.LOCATIONS} element={<Instalations />} />
              <Route
                path={`${ROUTES.LOCATIONS}/:locationId`}
                element={<InstallationsScreenWrapper />}
              />
              <Route
                path={`${ROUTES.LOCATIONS}/:locationId/:installationId`}
                element={<DeviceScreen />}
              />

              <Route path={ROUTES.EMPLOYEES} element={<>employees</>} />
              <Route path={ROUTES.DEVICES} element={<Devices />} />

              <Route path={ROUTES.ADMIN.ROOT} element={<AdminSectionWrapper />}>
                <Route
                  path={ROUTES.ADMIN.DEVICES}
                  element={<DevicesScreen />}
                />
                <Route path={ROUTES.ADMIN.CLIENTS} element={<>CLIENTS</>} />
              </Route>

              {/* <Route
                path={ROUTES.ADMIN.ROOT}
                element={
                  <ContentGuard authorizedRoles={["superAdmin"]}>
                    <AdminSectionWrapper>
                      <Route index element={<AdminSectionWrapper>hhhhh</AdminSectionWrapper>} />
                      <Route
                        path={ROUTES.ADMIN.DEVICES}
                        element={<DevicesScreen />}
                      />
                    </AdminSectionWrapper>
                  </ContentGuard>
                }
              /> */}

              <Route
                path={`${ROUTES.DEVICES}/:deviceId`}
                element={<DeviceScreen />}
              />
            </Route>
            <Route index path={ROUTES.LOGIN} element={<Login />} />
            <Route index path="*" element={<Navigate to={ROUTES.ROOT} />} />
          </Routes>
        </AuthProvider>
      </Router>
    </Box>
  );
}

export default Layout;
