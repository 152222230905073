import { Box, Button, Dialog, Divider, Typography } from "@mui/material";
import React, { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface ConfirmationModalProps {
  title: string;
  confirmationId: string;
  message?: string;
  onClose: () => void;
  onConfirm: (_id: string) => Promise<void>;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  title,
  message,
  confirmationId,
  onClose,
  onConfirm,
}): ReactElement => {
  const { t } = useTranslation();

  return (
    <Dialog open={true}>
      <Box sx={{ p: "30px", width: "400px" }}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="h6">{message}</Typography>
        <Divider sx={{ my: "30px" }} />
        <Box sx={{ display: "flex", justifyContent: "space-around" }}>
          <Button
            sx={{ width: "130px" }}
            variant="contained"
            onClick={() => onConfirm(confirmationId)}
          >
            {t("confirm")}
          </Button>
          <Button
            sx={{ width: "120px" }}
            color="secondary"
            variant="outlined"
            onClick={onClose}
          >
            {t("cancel")}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmationModal;
