import { Dayjs } from "dayjs";
import { daysOfWeek } from "./consts";
import { t } from "i18next";
import { formatNumber } from "./getDisplayDate";

export const createCronPattern = (
  time: Dayjs,
  everyDay: boolean,
  selectedDays: number[]
) => {
  const hour = time.hour();
  const minute = time.minute();

  if (everyDay) {
    return `${minute} ${hour} * * *`;
  }
  let days = "";
  selectedDays.forEach((item, index) => {
    if (index !== selectedDays.length - 1) {
      days += `${item},`;
    } else {
      days += `${item}`;
    }
  });
  return `${minute} ${hour} * * ${days}`;
};

export const gedDataFromPattern = (
  rule: string,
  dataType: "time" | "days" = "time"
) => {
  const ruleSegments = rule.split(" ");
  const selectedDays = ruleSegments[ruleSegments.length - 1];

  if (dataType == "time") {
    const hours = formatNumber(+ruleSegments[1]);
    const minutes = formatNumber(+ruleSegments[0]);
    return `${hours}:${minutes}h`;
  } else if (selectedDays === "*") {
    return t("everyDay");
  } else {
    let helper = "";
    const indexes = selectedDays.split(",").sort((a, b) => +a - +b);
    indexes.forEach((index, i) => {
      const day = t(daysOfWeek[+index]).slice(0, 3);
      if (i !== indexes.length - 1) {
        helper += `${day}, `;
      } else {
        helper += `${day}`;
      }
    });
    return helper;
  }
};
