export const getShorterText = (text: string, count: number = 30): string => {
  const length = text.length;

  if (text) {
    if (length <= count) {
      return text;
    }
    return `${text.slice(0, count).trim()}...`;
  }
  return "--";
};
