import MenuItem from "@mui/material/MenuItem";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { FC, ReactElement } from "react";

type Props = TextFieldProps & {
  data: {
    label: string;
  };
  options: { value?: number | string; label: string }[];
};

const SelectElement: FC<Props> = ({ data, options, ...rest }): ReactElement => {
  const { label } = data;

  return (
    <TextField
      fullWidth
      {...rest}
      id="outlined-select-currency"
      select
      label={label}
      sx={{ minWidth: "100px" }}
      // value={values[name] as string}
      // error={touched[name] && !!errors[name]}
      // helperText={getErrorMessage()}
    >
      {options.map(({ value, label }) => {
        const itemValue = value;
        return (
          <MenuItem
            key={itemValue}
            value={itemValue}
            // sx={{ backgroundColor: "white", color: "black" }}
          >
            {label}
          </MenuItem>
        );
      })}
    </TextField>
    // <FormControl
    //   fullWidth
    //   sx={{ minWidth: 120 }}
    //   error={touched[name] && !!errors[name]}
    // >
    //   <InputLabel id="demo-simple-select-error-label">
    //     {translatedLabel}
    //   </InputLabel>
    //   <Select
    //     {...rest}
    //     {...getFieldProps(name)}
    //     labelId="demo-simple-select-error-label"
    //     id="demo-simple-select-error"
    //     value={values[name] as string}
    //     label="Age"
    //     renderValue={(value) => {
    //       return `${
    //         i18n.t(
    //           selectOptions.find(
    //             (item) => item.value === value || item.id === value
    //           )?.label as string
    //         ) as string
    //       }`;
    //     }}
    //   >

    //   </Select>
    //   <FormHelperText>{getErrorMessage()}</FormHelperText>
    // </FormControl>
  );
};

export default SelectElement;
