import Button from "@mui/material/Button";
import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";
import { useHttp } from "../../hooks/useHttpClient";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { getSelectOptions } from "./getAlertLabels";
import { useMemo } from "react";
import MenuItem from "@mui/material/MenuItem";
import { useParams } from "react-router-dom";

interface FormProps {
  type: string;
  deviceId: number;
  valueDisabled: boolean
  getAlerts: () => Promise<void>;
}

const CreationForm: React.FC<FormProps> = ({ type, deviceId,valueDisabled, getAlerts }) => {
  const { installationId } = useParams();
  const HttpCreate = useHttp();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      alert: "",
      value: valueDisabled ? "0.5" : "",
      email: "",
    },
  });

  const handleCreate = async (values: any) => {
    const { alert, value, email } = values;
    await HttpCreate.newRequest(`/alerts`, {
      method: "POST",
      body: {
        deviceId,
        email,
        installationId,
        state: alert,
        value,
      },
      successMessage: "alertSucessfullyCreated",
    });
    getAlerts();
  };

  const formFields: {
    name: "alert" | "value" | "email";
    rules: Record<any, any>;
    props: any;
    xs?: number;
  }[] = useMemo(
    () => [
      {
        name: "alert",
        rules: {
          required: "requiredField",
        },
        props: {
          select: true,
          options: getSelectOptions(type).map((label, i) => ({
            value: `${i + 1}`,
            label,
          })),
        },
      },
      {
        name: "value",
        rules: {
          required: valueDisabled ? false : "requiredField",
        },
        props: {
          type: "number",
          InputProps:{
            readOnly: valueDisabled,
          }
        },
      },
      {
        name: "email",
        rules: {
          required: "requiredField",
          validate: (value: string) =>
            /\S+@\S+\.\S+/.test(value) || "invalidEmail",
        },
        props: {
          type: "email",
        },
        xs: 4,
      },
    ],
    []
  );

  return (
    <form onSubmit={handleSubmit(handleCreate)}>
      <Grid container sx={{ mt: "0px", mb: "40px" }} spacing={3}>
        <Grid item xs={12} sx={{}}>
          <Typography mt="-20px" variant="h6">
            {t("createNewAlert")}
          </Typography>
        </Grid>
        {formFields.map(({ name, rules, props, xs = 3 }) => (
          <Grid key={name} item xs={xs} sx={{ height: "75px" }}>
            <Controller
              name={name as any}
              rules={rules}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  {...props}
                  fullWidth
                  label={t(name)}
                  error={!!errors[name]}
                  helperText={t(errors[name]?.message as string, {
                    value: errors[name],
                  })}
                >
                  {props.options?.map(
                    ({ value, label }: { value: number; label: string }) => {
                      const itemValue = value;
                      return (
                        <MenuItem key={itemValue} value={itemValue}>
                          {label}
                        </MenuItem>
                      );
                    }
                  )}
                </TextField>
              )}
            />
          </Grid>
        ))}

        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button variant="contained" sx={{ width: "130px" }} type="submit">
            {HttpCreate.loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t("save")
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreationForm;
