import Box from "@mui/material/Box";
import React, { FC, ReactElement, useEffect } from "react";
import TooltipIconButton from "./TooltipIconButton";

interface FullScreenPreviewProps {
  children: React.ReactElement;
  onClose: () => void;
}

const FullScreenPreview: FC<FullScreenPreviewProps> = ({
  children,
  onClose,
}): ReactElement => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 2000,
        backgroundColor: "rgba(0,0,0,0.9)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ position: "absolute", right: 0, top: 0 }}>
        <TooltipIconButton
          title="exitFullScreen"
          color="info"
          onClick={onClose}
        />
      </Box>
      <Box
        sx={{
          height: "95%",
          width: "94vw",
          top: 0,
          left: 0,
          zIndex: 10000,
          backgroundColor: "#fff",
          p: "40px",
          pl: "50px",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default FullScreenPreview;
