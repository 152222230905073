import React, { FC, ReactElement } from "react";
import WifiRoundedIcon from "@mui/icons-material/WifiRounded";
import Wifi2BarRoundedIcon from "@mui/icons-material/Wifi2BarRounded";
import Wifi1BarRoundedIcon from "@mui/icons-material/Wifi1BarRounded";
import WifiOffRoundedIcon from "@mui/icons-material/WifiOffRounded";
import { Box, Typography, useTheme } from "@mui/material";

const FullWiFi = ({ percentage }: { percentage: number }) => {
  const theme = useTheme();
  if (!percentage) {
    return <WifiOffRoundedIcon color="disabled" sx={{ mr: "24px" }} />;
  } else if (percentage > 74) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <g clip-path="url(#clip0_1_2)">
          <path
            d="M18 32.54C19.6569 32.54 21 31.1969 21 29.54C21 27.8831 19.6569 26.54 18 26.54C16.3431 26.54 15 27.8831 15 29.54C15 31.1969 16.3431 32.54 18 32.54Z"
            fill={theme.palette.success.main}
          />
          <path
            d="M32.76 9.38C28.3275 6.60604 23.204 5.135 17.975 5.135C12.746 5.135 7.62252 6.60604 3.19 9.38C3.01786 9.48514 2.86853 9.6237 2.75083 9.7875C2.63313 9.9513 2.54943 10.137 2.50469 10.3337C2.45995 10.5304 2.45506 10.734 2.49033 10.9326C2.52559 11.1312 2.60028 11.3207 2.71 11.49L2.82 11.66C3.0309 11.9784 3.356 12.2036 3.72818 12.2892C4.10035 12.3748 4.49119 12.3142 4.82 12.12C8.75346 9.64859 13.3046 8.3375 17.95 8.3375C22.5954 8.3375 27.1465 9.64859 31.08 12.12C31.4088 12.3142 31.7996 12.3748 32.1718 12.2892C32.544 12.2036 32.8691 11.9784 33.08 11.66L33.19 11.49C33.302 11.3238 33.3797 11.1369 33.4187 10.9403C33.4578 10.7437 33.4572 10.5413 33.4172 10.3449C33.3772 10.1485 33.2985 9.96202 33.1856 9.79636C33.0728 9.6307 32.9281 9.48916 32.76 9.38Z"
            fill={theme.palette.success.main}
          />
          <path
            d="M28.82 15.44C25.5616 13.4197 21.8039 12.3493 17.97 12.3493C14.1361 12.3493 10.3784 13.4197 7.12 15.44C6.95086 15.5468 6.80462 15.686 6.68976 15.8498C6.57491 16.0135 6.49372 16.1984 6.45093 16.3938C6.40813 16.5892 6.40457 16.7911 6.44047 16.9879C6.47636 17.1846 6.55098 17.3723 6.66 17.54L6.77 17.71C6.98091 18.0284 7.30601 18.2536 7.67818 18.3392C8.05036 18.4248 8.44119 18.3642 8.77 18.17C11.526 16.4584 14.7057 15.5513 17.95 15.5513C21.1943 15.5513 24.374 16.4584 27.13 18.17C27.4588 18.3642 27.8496 18.4248 28.2218 18.3392C28.594 18.2536 28.9191 18.0284 29.13 17.71L29.24 17.54C29.3509 17.3747 29.428 17.1891 29.4669 16.994C29.5058 16.7988 29.5057 16.5978 29.4667 16.4027C29.4277 16.2075 29.3504 16.022 29.2394 15.8568C29.1285 15.6916 28.9859 15.5499 28.82 15.44Z"
            fill={theme.palette.success.main}
          />
          <path
            d="M24.88 21.49C22.7934 20.2354 20.4047 19.5726 17.97 19.5726C15.5353 19.5726 13.1466 20.2354 11.06 21.49C10.8912 21.5958 10.7452 21.7342 10.6304 21.897C10.5157 22.0599 10.4345 22.244 10.3917 22.4385C10.3489 22.6331 10.3453 22.8342 10.381 23.0302C10.4168 23.2262 10.4912 23.4131 10.6 23.58L10.7 23.74C10.92 24.0652 11.2574 24.2927 11.6414 24.3747C12.0254 24.4567 12.4263 24.3869 12.76 24.18C14.3389 23.2505 16.1378 22.7604 17.97 22.7604C19.8022 22.7604 21.6011 23.2505 23.18 24.18C23.5149 24.386 23.9166 24.4542 24.3007 24.3703C24.6848 24.2864 25.0215 24.0569 25.24 23.73L25.34 23.57C25.4485 23.4039 25.5228 23.2178 25.5584 23.0226C25.5941 22.8274 25.5904 22.627 25.5475 22.4332C25.5047 22.2395 25.4235 22.0563 25.3089 21.8943C25.1943 21.7323 25.0485 21.5949 24.88 21.49Z"
            fill={theme.palette.success.main}
          />
        </g>
        <defs>
          <clipPath id="clip0_1_2">
            <rect width="30" height="30" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (percentage > 49) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
      >
        <g clip-path="url(#clip0_1_8)">
          <path
            d="M18 32.54C19.6569 32.54 21 31.1969 21 29.54C21 27.8831 19.6569 26.54 18 26.54C16.3431 26.54 15 27.8831 15 29.54C15 31.1969 16.3431 32.54 18 32.54Z"
            fill={theme.palette.warning.light}
          />
          <path
            d="M28.82 15.44C25.5616 13.4197 21.8039 12.3493 17.97 12.3493C14.1361 12.3493 10.3784 13.4197 7.12 15.44C6.95086 15.5468 6.80462 15.686 6.68976 15.8498C6.57491 16.0135 6.49372 16.1984 6.45093 16.3938C6.40813 16.5892 6.40457 16.7911 6.44047 16.9879C6.47636 17.1846 6.55098 17.3723 6.66 17.54L6.77 17.71C6.98091 18.0284 7.30601 18.2536 7.67818 18.3392C8.05036 18.4248 8.44119 18.3642 8.77 18.17C11.526 16.4584 14.7057 15.5513 17.95 15.5513C21.1943 15.5513 24.374 16.4584 27.13 18.17C27.4588 18.3642 27.8496 18.4248 28.2218 18.3392C28.594 18.2536 28.9191 18.0284 29.13 17.71L29.24 17.54C29.3509 17.3747 29.428 17.1891 29.4669 16.994C29.5058 16.7988 29.5057 16.5978 29.4667 16.4027C29.4277 16.2075 29.3504 16.022 29.2394 15.8568C29.1285 15.6916 28.9859 15.5499 28.82 15.44Z"
            fill={theme.palette.warning.light}
          />
          <path
            d="M24.88 21.49C22.7934 20.2354 20.4047 19.5726 17.97 19.5726C15.5353 19.5726 13.1466 20.2354 11.06 21.49C10.8912 21.5958 10.7452 21.7342 10.6304 21.897C10.5157 22.0599 10.4345 22.244 10.3917 22.4385C10.3489 22.6331 10.3453 22.8342 10.381 23.0302C10.4168 23.2262 10.4912 23.4131 10.6 23.58L10.7 23.74C10.92 24.0652 11.2574 24.2927 11.6414 24.3747C12.0254 24.4567 12.4263 24.3869 12.76 24.18C14.3389 23.2505 16.1378 22.7604 17.97 22.7604C19.8022 22.7604 21.6011 23.2505 23.18 24.18C23.5149 24.386 23.9166 24.4542 24.3007 24.3703C24.6848 24.2864 25.0215 24.0569 25.24 23.73L25.34 23.57C25.4485 23.4039 25.5228 23.2178 25.5584 23.0226C25.5941 22.8274 25.5904 22.627 25.5475 22.4332C25.5047 22.2395 25.4235 22.0563 25.3089 21.8943C25.1943 21.7323 25.0485 21.5949 24.88 21.49Z"
            fill={theme.palette.warning.light}
          />
        </g>
        <defs>
          <clipPath id="clip0_1_8">
            <rect width="36" height="36" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (percentage > 24) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
      >
        <g clip-path="url(#clip0_1_13)">
          <path
            d="M18 32.54C19.6569 32.54 21 31.1969 21 29.54C21 27.8831 19.6569 26.54 18 26.54C16.3431 26.54 15 27.8831 15 29.54C15 31.1969 16.3431 32.54 18 32.54Z"
            fill={theme.palette.warning.dark}
          />
          <path
            d="M24.88 21.49C22.7934 20.2354 20.4047 19.5726 17.97 19.5726C15.5353 19.5726 13.1466 20.2354 11.06 21.49C10.8912 21.5958 10.7452 21.7342 10.6304 21.897C10.5157 22.0599 10.4345 22.244 10.3917 22.4385C10.3489 22.6331 10.3453 22.8342 10.381 23.0302C10.4168 23.2262 10.4912 23.4131 10.6 23.58L10.7 23.74C10.92 24.0652 11.2574 24.2927 11.6414 24.3747C12.0254 24.4567 12.4263 24.3869 12.76 24.18C14.3389 23.2505 16.1378 22.7604 17.97 22.7604C19.8022 22.7604 21.6011 23.2505 23.18 24.18C23.5149 24.386 23.9166 24.4542 24.3007 24.3703C24.6848 24.2864 25.0215 24.0569 25.24 23.73L25.34 23.57C25.4485 23.4039 25.5228 23.2178 25.5584 23.0226C25.5941 22.8274 25.5904 22.627 25.5475 22.4332C25.5047 22.2395 25.4235 22.0563 25.3089 21.8943C25.1943 21.7323 25.0485 21.5949 24.88 21.49Z"
            fill={theme.palette.warning.dark}
          />
        </g>
        <defs>
          <clipPath id="clip0_1_13">
            <rect width="36" height="36" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
      >
        <g clip-path="url(#clip0_1_17)">
          <path
            d="M18 32.54C19.6569 32.54 21 31.1969 21 29.54C21 27.8831 19.6569 26.54 18 26.54C16.3431 26.54 15 27.8831 15 29.54C15 31.1969 16.3431 32.54 18 32.54Z"
            fill={theme.palette.error.dark}
          />
        </g>
        <defs>
          <clipPath id="clip0_1_17">
            <rect width="36" height="36" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
};
interface WiFiProps {
  strength: number;
}

const WiFi: FC<WiFiProps> = ({ strength = 0 }): ReactElement => {
  let icon = <WifiOffRoundedIcon color="disabled" sx={{}} />;
  const percentage = strength ? 100 - ((strength - 30) / 70) * 100 : 0;
  if (strength) {
    if (percentage > 69) {
      icon = <WifiRoundedIcon color="primary" />;
    } else if (percentage > 39) {
      icon = <Wifi2BarRoundedIcon color="warning" />;
    } else if (percentage > 10) {
      icon = <Wifi1BarRoundedIcon color="error" />;
    }
  }

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
    >
      {+percentage > 10 && (
        <Typography mr="6px" variant="body2">{percentage.toFixed(0) ?? 0}%</Typography>
      )}
      {icon} {/* <FullWiFi percentage={percentage} /> */}
      {/* {Aaa} */}
    </Box>
  );
};

export default WiFi;
