import React, {
  FC,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Grid from "@mui/material/Grid";
import CreationForm from "./CreationForm";
import TableWrapper from "../../components/table/TableWrapper";
import { useHttp } from "../../hooks/useHttpClient";
import TooltipIconButton from "../../components/TooltipIconButton";
import { useParams } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { getDisplayDateTime } from "../../utils/getDisplayDate";
import { t } from "i18next";
import { gedDataFromPattern } from "../../utils/cronParser";
import ConfirmationModal from "../../components/ConfirmationModal";

const headers = ["task", "time", "days", "active", "created", ""];

interface SchedulerScreenProps {}

const SchedulerScreen: FC<SchedulerScreenProps> = (): ReactElement => {
  const { installationId } = useParams();
  const [confirmationId, setConfirmationId] = useState<string | null>(null);

  const HttpGet = useHttp();
  const HttpDelete = useHttp();
  const HttpActivityUpdate = useHttp();

  const offset = useRef(0);
  const limit = useRef(10);

  const getTasks = async () => {
    HttpGet.newRequest(
      `/tasks/${installationId}?limit=${limit.current}&offset=${offset.current}`
    );
  };

  const handleTaskActivity = async (value: number, _id: number) => {
    await HttpActivityUpdate.newRequest(`/tasks/activity/${_id}`, {
      method: "PATCH",
      body: { active: value === 1 ? 0 : 1 },
      successMessage: "taskSucessfullyUpdated",
    });
    getTasks();
  };

  const removeTask = async (_id: string) => {
    setConfirmationId(null);
    await HttpDelete.newRequest(`/tasks/${_id}`, {
      method: "DELETE",
      successMessage: "taskSucessfullyRemoved",
    });
    getTasks();
  };

  useEffect(() => {
    getTasks();
  }, []);

  const handlePagination = (_offset: number, _limit: number) => {
    offset.current = _offset;
    limit.current = _limit;
    getTasks();
  };

  const keys = useMemo(
    () => [
      {
        key: "state",
        render: (value: any, data: any) => {
          return value ? t("turnOn") : t("turnOff");
        },
      },
      {
        key: "cronRule",
        render: (value: any) => gedDataFromPattern(value, "time"),
      },
      {
        key: "cronRule",
        render: (value: any) => gedDataFromPattern(value, "days"),
      },
      {
        key: "active",
        render: (value: number, data: any) => (
          <Switch
            {...{ inputProps: { "aria-label": "Switch demo" } }}
            checked={!!value}
            onClick={() => handleTaskActivity(value, data._id)}
          />
        ),
      },
      {
        key: "created",
        render: (value: string) => getDisplayDateTime(value),
      },
      {
        key: "_id",
        render: (value: string) => (
          <TooltipIconButton
            title="delete"
            color="secondary"
            onClick={() => setConfirmationId(value)}
          />
        ),
      },
    ],
    []
  );

  return (
    <Grid container columnSpacing={5}>
      <Grid item xs={9}>
        <TableWrapper
          data={HttpGet.items}
          headers={headers}
          keys={keys}
          page={offset.current}
          loading={HttpGet.loading}
          pageRows={limit.current}
          skeletonCount={5}
          totalCount={HttpGet.totalCount}
          handlePagination={handlePagination}
          handleRowSelect={() => null}
          hover={false}
        />
      </Grid>
      <Grid item xs={3}>
        <CreationForm getData={getTasks} />
      </Grid>
      {!!confirmationId && (
        <ConfirmationModal
          title={`${t("sureDeleteItem")} ${t("plan")}?`}
          confirmationId={confirmationId}
          onClose={() => setConfirmationId(null)}
          onConfirm={removeTask}
        />
      )}
    </Grid>
  );
};

export default SchedulerScreen;
