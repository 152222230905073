import {
  Card,
  Grid,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import TableWrapper from "../../components/table/TableWrapper";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDisplayDateTime } from "../../utils/getDisplayDate";
import TooltipIconButton from "../../components/TooltipIconButton";
import { getShorterText } from "../../utils/getShorterText";
import ConfirmationModal from "../../components/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import TooltipIcon from "../../components/TooltipIcon";
import { useHttp } from "../../hooks/useHttpClient";
import CreationForm from "./CreationForm";

const headers = ["name", "description", "installationsCount", "created", ""];

const Locations = () => {
  const offset = useRef(0);
  const limit = useRef(10);
  const [confirmationId, setConfirmationId] = useState<string | null>(null);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const HttpGet = useHttp();
  const HttpDelete = useHttp();

  const getData = async () => {
    await HttpGet.newRequest(
      `/locations?limit=${limit.current}&offset=${offset.current}`
    );
  };

  const removeLocation = async () => {
    setConfirmationId(null);
    await HttpDelete.newRequest(`/locations/${confirmationId}`, {
      method: "DELETE",
      successMessage: "locationSucessfullyRemoved",
    });
    await getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const keys = useMemo(
    () => [
      {
        key: "name",
      },
      {
        key: "description",
        render: (value: string) => (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {getShorterText(value, 15)}{" "}
            <TooltipIcon sx={{ ml: "4px" }} title={value} />
          </Box>
        ),
      },
      {
        key: "installationsCount",
      },
      {
        key: "created",
        render: (value: string) => getDisplayDateTime(value),
      },
      {
        key: "_id",
        render: (value: string, data: any) => (
          <TooltipIconButton
            title="delete"
            color="secondary"
            disabledMessage={
              !data.installationsCount ? "" : "locationDeleteDisabled"
            }
            onClick={() => setConfirmationId(value)}
          />
        ),
        preventClick: true,
      },
    ],
    []
  );

  const handlePagination = (_offset: number, _limit: number) => {
    offset.current = _offset;
    limit.current = _limit;
    getData();
  };

  const handleRowSelect = (id: string) => {
    navigate(`${id}`);
  };

  return (
    <Grid container sx={{}} columnSpacing={2}>
      <Grid item xs={12}>
        <PageHeader data={[{ label: "locations", path: "/locations" }]} />
      </Grid>
      <Grid item xs={9} sx={{ my: "10px", pr: "20px" }}>
        <TableWrapper
          data={HttpGet.items}
          headers={headers}
          keys={keys}
          page={offset.current}
          loading={HttpGet.loading}
          pageRows={limit.current}
          skeletonCount={5}
          totalCount={HttpGet.totalCount}
          handlePagination={handlePagination}
          handleRowSelect={handleRowSelect}
        />
      </Grid>
      <Grid item xs={3} sx={{}}>
        <Card sx={{ p: "20px", mt: "10px" }} elevation={3}>
          <Typography mb="26px" variant="h6">
            {t("createNewLocation")}
          </Typography>
          <CreationForm getData={getData} />
        </Card>
      </Grid>

      {!!confirmationId && (
        <ConfirmationModal
          title={`${t("sureDeleteItem")} ${t("location")}?`}
          confirmationId={confirmationId}
          onClose={() => setConfirmationId(null)}
          onConfirm={removeLocation}
        />
      )}
    </Grid>
  );
};

export default Locations;
