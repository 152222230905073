import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";
import { useHttp } from "../../hooks/useHttpClient";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import dayjs from "dayjs";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import { daysOfWeek } from "../../utils/consts";
import { Card, Typography } from "@mui/material";
import { createCronPattern } from "../../utils/cronParser";

interface FormProps {
  getData: () => Promise<void>;
}

const CreationForm: React.FC<FormProps> = ({ getData }) => {
  const { installationId } = useParams();
  const [everyDay, setEveryDay] = useState(true);
  const [selectedDays, setSelectedDays] = useState<number[]>([0]);
  const HttpCreate = useHttp();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      action: "",
      everyDay: true,
      selectedTime: dayjs(new Date()),
    },
  });

  const handleCreate = async (data: any) => {
    const { action, everyDay, selectedTime } = data;
    const cronRule = createCronPattern(selectedTime, everyDay, selectedDays);
    await HttpCreate.newRequest(`/tasks`, {
      method: "POST",
      body: {
        installationId,
        state: action,
        cronRule,
      },
      successMessage: "taskSucessfullyCreated",
    });
    getData();
  };

  const handleDaySelect = (index: number, value: boolean) => {
    setSelectedDays((prev) => {
      if (!value) {
        if (prev.length !== 1) {
          return prev.filter((item) => item !== index);
        }
        return prev;
      }
      if (prev.length === 6) {
        setValue("everyDay", true);
        setEveryDay(true);
        return [0];
      }
      return [...prev, index];
    });
  };
  return (
    <Card sx={{ p: "30px 26px" }} elevation={3}>
      <Typography mb="30px" variant="h6">
        {t("createNewPlan")}
      </Typography>
      <form onSubmit={handleSubmit(handleCreate)}>
        <Controller
          name="action"
          rules={{
            required: "requiredField",
          }}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              select
              fullWidth
              label={t("action")}
              error={!!errors["action"]}
              helperText={t(errors["action"]?.message as string, {
                value: errors["action"],
              })}
            >
              {[
                { label: "turnOn", value: 1 },
                { label: "turnOff", value: 0 },
              ].map(({ value, label }: { value: number; label: string }) => {
                const itemValue = value;
                return (
                  <MenuItem key={itemValue} value={itemValue}>
                    {t(label)}
                  </MenuItem>
                );
              })}
            </TextField>
          )}
        />

        <Box my="24px" ml="12px">
          <FormControlLabel
            control={
              <Controller
                name="everyDay"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                      setEveryDay(e.target.checked);
                    }}
                  />
                )}
              />
            }
            label={t("everyDay")}
          />
        </Box>

        {!everyDay && (
          <Grid container mb="24px" px="20px">
            {daysOfWeek.map((item, index) => (
              <Grid key={item} item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedDays.includes(index)}
                      onChange={(e) => {
                        handleDaySelect(index, e.target.checked);
                      }}
                    />
                  }
                  label={t(item)}
                />
              </Grid>
            ))}
          </Grid>
        )}
        <Controller
          name="selectedTime"
          control={control}
          render={({ field }) => (
            <MobileTimePicker
              {...field}
              ampm={false}
              sx={{ width: "100%" }}
              label={t("time")}
              views={["hours", "minutes"]}
            />
          )}
        />

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" sx={{ mt: "40px" }} type="submit">
            {HttpCreate.loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t("save")
            )}
          </Button>
        </Box>
      </form>
    </Card>
  );
};

export default CreationForm;
