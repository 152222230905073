import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import React, { FC, ReactElement } from "react";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { getDisplayDateTime } from "./getDisplayDate";
import DescriptionIcon from "@mui/icons-material/Description";
import { useSnackbar } from "../providers/SnackbarContext";
import { useTranslation } from "react-i18next";

interface SCVExportProps {
  data: any[];
}

const SCVExport: FC<SCVExportProps> = ({ data }): ReactElement => {
  const { openSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const csvConfig = mkConfig({
    useKeysAsHeaders: true,
    filename: `Monitor Report - ${getDisplayDateTime()}`,
    showTitle: false,
  });

  const generate = () => {
    const csv = generateCsv(csvConfig)(data);
    openSnackbar("downloaded", "success");
    download(csvConfig)(csv);
  };

  return (
    <Box alignSelf="flex-end">
      <Button
        startIcon={<DescriptionIcon />}
        sx={{ mt: "24px" }}
        variant="outlined"
        onClick={generate}
      >
        {t("exportCSV")}
      </Button>{" "}
    </Box>
  );
};

export default SCVExport;
