import Box from "@mui/material/Box";
import React, { FC, ReactElement, useEffect } from "react";
import { useParams } from "react-router-dom";
import LiveDataScreen from "./Live";
import Settings from "./Settings";
import Report from "./ReportScreenWrapper";
import Alerts from "./alerts/Alerts";
import { useTranslation } from "react-i18next";
import PageHeader from "../components/PageHeader";
import { useHttp } from "../hooks/useHttpClient";
import TabsWrapper from "../components/TabsWrapper";
import SchedulerScreen from "./scheduler/SchedulerScreen";

interface DeviceScreenProps {}

const defaultLabels = [
  "report",
  "alerts",
  "settings",
  "live",
  "3D",
  "TODO",
];

const labels: Record<string, string[]> = {
  switch: ["report", "alerts", "schedule"],
  "open&close": ["report", "alerts", "live", "3D"],
};

const DeviceScreen: FC<DeviceScreenProps> = (): ReactElement => {
  const { installationId, locationId } = useParams();
  const { t } = useTranslation();

  const HttpGet = useHttp();

  useEffect(() => {
    HttpGet.newRequest(`/installations/${installationId}`);
  }, []);

  const {
    period = 2,
    deviceId,
    deviceType,
    type,
    name,
    locationName,
  } = HttpGet.item;
  const screens: Record<string, { condition: boolean; screen: ReactElement }> =
    {
      report: {
        condition: !!deviceType,
        screen: <Report deviceType={deviceType} />,
      },
      alerts: {
        condition: !!deviceId,
        screen: <Alerts type={deviceType} deviceId={deviceId} />,
      },
      settings: {
        condition: !!period,
        screen: <Settings value={period} />,
      },
      schedule: {
        condition: !!deviceType,
        screen: <SchedulerScreen />,
      },
      live: {
        condition: !!deviceType,
        screen: <LiveDataScreen deviceType={deviceType} />,
      },
      "3D": {
        condition: !!deviceId,
        screen: <Box>3D</Box>,
      },
      TODO: {
        condition: !!deviceId,
        screen: (
          <>
            Alert Logs <br />
            Proper Alert Mail - Data <br />
            Custom Alert <br />
            Status <br />
            Avrg&Min&Max Panel <br />
            Export CSV <br />
            Chart Min&Max <br />
            Read device data only if attached to the instalation <br />
            Settings: Period, read in period or read on change and read
            differance <br />
            <strong>Premium:</strong> Custom Period filter for report, Lower
            Interval value, Live, Custom ALerts, More email options for the
            alert, More Accounts, More Instalations, Filter interval second
          </>
        ),
      },
    };

  const tabs = labels[deviceType] || defaultLabels;

  return (
    <Box sx={{ width: "100%" }}>
      <PageHeader
        data={[
          { label: "locations", path: "/locations" },
          {
            label: locationName || "loading",
            path: `/locations/${locationId}`,
          },
          {
            label: name ? `${name} - ${t(deviceType)}` : "loading",
            path: `/locations/${locationId}/${installationId}`,
          },
        ]}
      />

      <TabsWrapper tabs={tabs} screens={tabs.map((item) => screens[item])} />
    </Box>
  );
};

export default DeviceScreen;
