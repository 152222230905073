import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { FC, ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import { getPeriod } from "../utils/getPeriod";
import { getDisplayDateTime } from "../utils/getDisplayDate";

interface NonPeriodicDeviceStatePanelProps {
  data: any;
  deviceType: string;
  switchUpdateLoading: boolean;
  handleSwitchStateUpdate: (newState: number) => Promise<void>;
}

const NonPeriodicDeviceStatePanel: FC<NonPeriodicDeviceStatePanelProps> = ({
  data,
  deviceType,
  switchUpdateLoading,
  handleSwitchStateUpdate,
}): ReactElement => {
  const isSwitch = deviceType === "switch";
  const isActive = !!data.currentState;
  const { t } = useTranslation();

  const currentTimestamp = new Date().getTime();
  const lastReadingTimestamp = data.lastReadingCreation
    ? new Date(data.lastReadingCreation).getTime()
    : currentTimestamp;

  const lastReadingTime = (currentTimestamp - lastReadingTimestamp) / 1000;

  const totalActiveCount = data.totalActiveCount || 0;

  const totalActiveDuration = isActive
    ? data.totalActiveSeconds + lastReadingTime
    : data.totalActiveSeconds;
  const totalInactiveDuration = isActive
    ? data.totalInactiveSeconds
    : data.totalInactiveSeconds + lastReadingTime;

  const totalDuration = totalActiveDuration + totalInactiveDuration;

  const averageActive = useMemo(
    () => (totalActiveDuration * 100) / totalDuration || 0,
    [data]
  );
  const averageInactive = 100 - averageActive || 0;

  const activeStateAverageLabel = isSwitch ? "averageOn" : "averageOpen";
  const inactiveStateAverageLabel = isSwitch ? "averageOff" : "averageClosed";

  const getStatusLabel = (value: boolean) => {
    if (isSwitch) {
      return !value ? t("off") : t("on");
    }
    return !value ? t("closed") : t("open");
  };

  const buttonLabel = isActive ? t("turnOff") : t("turnOn");

  return (
    <Card
      sx={(theme) => ({
        width: "100%",
        mb: "30px",
        p: "30px",
        // [theme.breakpoints.up("lg")]: {
        //   ml: "35px",
        // },
      })}
    >
      <Grid container sx={{}} spacing={0.5}>
        <Grid item xs={8} sx={{ mt: "6px", mb: "30px" }}>
          <Typography variant="h5" sx={{ mr: "8px", my: "0px" }}>
            {`${t("currentState")}:`}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ mb: "30px" }}>
          <Typography
            variant="h4"
            color="primary"
            sx={{ my: "0px" }}
            textAlign="center"
          >
            {getStatusLabel(isActive)}
          </Typography>
        </Grid>

        <Grid item xs={9} sx={{}}>
          <Typography variant="h6">{t(activeStateAverageLabel)}</Typography>
        </Grid>
        <Grid item xs={3} sx={{}}>
          <Typography variant="h5" color="primary">
            {`${averageActive.toFixed(1)}%`}
          </Typography>
        </Grid>
        <Grid item xs={9} sx={{}}>
          <Typography variant="h6">{t(inactiveStateAverageLabel)}</Typography>
        </Grid>
        <Grid item xs={3} sx={{}}>
          <Typography variant="h5" color="primary">
            {`${averageInactive.toFixed(1)}%`}
          </Typography>
        </Grid>
        {!isSwitch && (
          <>
            <Grid item xs={9} sx={{}}>
              <Typography variant="h6">
                {`${t("totalOpenCount")}`}
                <Typography variant="body2">{`${t(
                  "since"
                )} ${getDisplayDateTime(
                  data?.installationCreation
                )} `}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{}}>
              <Typography variant="h5" color="primary">
                {`${totalActiveCount}`}
              </Typography>
            </Grid>
          </>
        )}
        {isSwitch && (
          <>
            <Grid item xs={9} sx={{}}>
              <Typography variant="h6">
                {t("totalOnTime")}{" "}
                <Typography variant="body2">{`${t(
                  "since"
                )} ${getDisplayDateTime(
                  data?.installationCreation
                )} `}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{}}>
              <Typography variant="h5" color="primary">
                {`${getPeriod(totalActiveDuration)}`}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ mt: "50px", display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                disabled={switchUpdateLoading}
                onClick={() => handleSwitchStateUpdate(isActive ? 0 : 1)}
                sx={{ minWidth: "140px", py: "6px", fontSize: "18px" }}
              >
                {switchUpdateLoading ? (
                  <CircularProgress
                    size={24}
                    color="inherit"
                    sx={{ my: "4px" }}
                  />
                ) : (
                  buttonLabel
                )}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  );
};

export default NonPeriodicDeviceStatePanel;
