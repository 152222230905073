import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import React, { FC, ReactElement, useEffect, useRef } from "react";
import { getDisplayDateTime } from "../../utils/getDisplayDate";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import { useHttp } from "../../hooks/useHttpClient";

interface AlertLogsProps {}

const AlertLogs: FC<AlertLogsProps> = (): ReactElement => {
  const { installationId } = useParams();
  const offsetValue = useRef(0);
  const { t } = useTranslation();

  const { newRequest, hasNextPage, items, loading, totalCount } = useHttp(true);
  const loadMore = async () => {
    await newRequest(
      `/alertLogs/${installationId}?limit=12&offset=${offsetValue.current}`
    );
    offsetValue.current++;
  };

  const { containerRef, childRef, onScroll } = useInfiniteScroll(
    loadMore,
    !loading && hasNextPage
  );

  useEffect(() => {
    loadMore();
  }, []);

  return (
    <Card
      onWheel={onScroll}
      ref={containerRef}
      elevation={2}
      sx={{
        width: "100%",
        p: "26px 0px 60px 18px",
        height: "74vh",
        position: "relative",
      }}
    >
      <Box
        sx={{
          height: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pr: "16px",
          mb: "32px",
        }}
      >
        <Typography variant="h5">{t("logs")}</Typography>
        <Typography variant="body2">
          {`${items.length} / ${totalCount}`}
        </Typography>
      </Box>{" "}
      <Box
        sx={{
          height: "100%",
          overflowY: "scroll",
          pr: "10px",
        }}
      >
        {items.length ? (
          items.map(({ message, value, created, _id }) => (
            <Box key={_id} mb="14px">
              {" "}
              <Typography mb="10px" variant="body1">
                {`${t(message)} ${t(value || "")}`}
              </Typography>{" "}
              <Typography mb="10px" variant="body2" align="right">
                {getDisplayDateTime(created)}
              </Typography>{" "}
              <Divider />
            </Box>
          ))
        ) : (
          <Typography align="center" mt="20px" variant="body1">
            {t("noResultsFound")}
          </Typography>
        )}
        {hasNextPage && (
          <Box ref={childRef}>
            {Array(6)
              .fill(0)
              .map((x, i) => (
                <Box
                  key={i}
                  mb={`12px`}
                  width={1}
                  borderBottom="1px solid lightgray"
                  pb="10px"
                >
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    width="100%"
                    height={12}
                  />
                </Box>
              ))}{" "}
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default AlertLogs;
