import { useCallback, useRef, useState } from "react";
import { useSnackbar } from "../providers/SnackbarContext";
import { useLogOut } from "./useLogOut";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../Layout";
// const localUrl = "http://test-env.eba-qmuqwbig.eu-central-1.elasticbeanstalk.com/api"
// const localUrl = "http://stefan.pikado.net/api";
const localUrl = "http://localhost:2302/api";

export const baseURL =
  process.env.NODE_ENV === "production"
    ? "http://test-env.eba-qmuqwbig.eu-central-1.elasticbeanstalk.com/api"
    : localUrl;

interface Response {
  data: {
    ok: boolean;
    items: any[];
    item: any;
    error: false;
    errorMessage: string;
    pagination: Pagination | null;
  };
}

interface Pagination {
  totalCount: number;
  hasNextPage: boolean;
  offset: number;
  limit: number;
}

type NewRequest = (
  path: string,
  options?: {
    method?: "GET" | "POST" | "PATCH" | "DELETE";
    body?: any;
    successMessage?: string;
    awaitResponse?: number;
    throwError?: boolean;
  }
) => Promise<void>;

type UseHttp = (infiniteScroll?: boolean) => {
  newRequest: NewRequest;
  item: any;
  items: any[];
  loading: boolean;
  totalCount: number;
  hasNextPage: boolean;
  offset: number;
  limit: number;
};

const getHeaders = (): HeadersInit => {
  const token = localStorage.getItem("accessToken");
  return {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    authorization: token ?? "",
    "Client-Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
};

const awaitResponse = async (duration?: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      return resolve("");
    }, duration ?? 0);
  });
};

export const useHttp: UseHttp = (infiniteScroll = false) => {
  const [loading, setLoading] = useState(false);

  const itemsList = useRef<any[]>([]);
  const dataItem = useRef<any>({});

  const paginationData = useRef<Pagination>({} as Pagination);
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const logOut = useLogOut();

  const newRequest: NewRequest = useCallback(async (path, options) => {
    const reqData = {
      headers: getHeaders(),
      method: options?.method ?? "GET",
    };
    setLoading(true);
    try {
      const response = await fetch(
        baseURL + path,
        options?.body
          ? { ...reqData, body: JSON.stringify(options?.body) }
          : reqData
      );

      const {
        data: { ok, items, item, errorMessage, pagination },
      } = (await response.json()) as Response;
      await awaitResponse(options?.awaitResponse);
      if (!ok) {
        if (response.status === 401) {
          logOut();
        }
        if (response.status === 403) {
          navigate(ROUTES.ROOT);
        }
        throw new Error(errorMessage);
      }
      if (infiniteScroll) {
        itemsList.current = [...itemsList.current, ...items];
      } else {
        itemsList.current = items;
      }
      if (item) {
        dataItem.current = item;
      }
      paginationData.current = pagination ?? ({} as Pagination);
      if (options?.successMessage) {
        openSnackbar(options?.successMessage, "success");
      }
    } catch (error: any) {
      openSnackbar(error.message, "error");
      if (options?.throwError) {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  }, []);
  return {
    newRequest,
    loading,
    ...paginationData.current,
    items: itemsList.current,
    item: dataItem.current,
  };
};
