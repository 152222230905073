import {
  AppBar,
  Box,
  Divider,
  Drawer,
  List,
  Toolbar,
  Typography,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SettingsRemoteRoundedIcon from "@mui/icons-material/SettingsRemoteRounded";
import LanguageSelect from "../providers/localization/LanguageSelect";
import AccountMenu from "../components/AccountDropdown";
import NavLink from "../components/NavLink";
import PlaceIcon from "@mui/icons-material/Place";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import { ROUTES } from "../Layout";
import { useLogOut } from "../hooks/useLogOut";
import ContentGuard from "../providers/guards/ContentGuard";
import AccessibilityNewRoundedIcon from "@mui/icons-material/AccessibilityNewRounded";

const drawerWidth = 220;

const links = [
  {
    label: "locations",
    path: "/locations",
    icon: <PlaceIcon />,
  },

  {
    label: "devices",
    path: "/devices",
    icon: <SettingsRemoteRoundedIcon />,
  },
  {
    label: "employees",
    path: "/employees",
    icon: <PeopleOutlineOutlinedIcon />,
  },
];

const adminLinks = [
  {
    label: "clients",
    path: "/admin/clients",
    icon: <AccessibilityNewRoundedIcon />,
  },
  {
    label: "devices",
    path: "/admin/devices",
    icon: <SettingsRemoteRoundedIcon />,
  },
];

const Wrapper = (props: any) => {
  const { t } = useTranslation();

  const logOut = useLogOut();

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <AppBar
        position="fixed"
        sx={{
          display: "flex",
          width: `100%`,
          ml: `${drawerWidth}px`,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <Typography variant="h5" noWrap component="div">
            Monitoring Solutions
          </Typography>
          <Box flexGrow={1}></Box>
          <LanguageSelect />
          {/* <AccountMenu /> */}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          display: "flex",
          position: "relative",
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-between",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        {/* <Toolbar /> */}
        {/* <Divider /> */}
        <ContentGuard authorizedRoles={["superAdmin", "client"]}>
          <>
            <Typography sx={{ ml: "16px", mt: "90px", fontWeight: 600 }}>
              DASHBOARD
            </Typography>
            <List sx={{ mt: "10px" }}>
              {links.map(({ label, path, icon }, index) => {
                return (
                  <NavLink path={path} label={label} key={path} icon={icon} />
                );
              })}

              {/* <Divider /> */}
            </List>
          </>
        </ContentGuard>

        <ContentGuard authorizedRoles={["superAdmin"]}>
          <>
            <Typography sx={{ ml: "16px", mt: "8px", fontWeight: 600 }}>
              ADMIN
            </Typography>
            <List sx={{ mt: "10px" }}>
              {adminLinks.map(({ label, path, icon }, index) => {
                return (
                  <NavLink path={path} label={label} key={path} icon={icon} />
                );
              })}
              {/* <Divider /> */}
            </List>
          </>
        </ContentGuard>
        <List
          sx={{
            mt: "0px",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
          }}
        >
          <div style={{ marginTop: "0px" }}></div>
          <ListItem disablePadding>
            <ListItemButton onClick={logOut}>
              <ListItemIcon>
                <LogoutOutlinedIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography>{t("logOut")}</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />

        <Outlet />
      </Box>
    </Box>
  );
};

export default Wrapper;
