import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";
import { useHttp } from "../../../hooks/useHttpClient";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import { useParams } from "react-router-dom";

const formFields: {
  name: "name" | "description";
  rules: Record<any, any>;
  props: any;
}[] = [
  {
    name: "name",
    rules: {
      required: "requiredField",
      minLength: { value: 2, message: t("minLength", { value: 2 }) },
    },
    props: {},
  },
  {
    name: "description",
    rules: {
      required: "requiredField",
      minLength: { value: 8, message: t("minLength", { value: 8 }) },
    },
    props: {
      multiline: true,
      minRows: 3,
    },
  },
];

interface FormProps {
  devices: any[];
  getData: () => Promise<void>;
  getAvailableDevices: () => Promise<void>;
}

const CreationForm: React.FC<FormProps> = ({
  devices,
  getData,
  getAvailableDevices,
}) => {
  const { locationId } = useParams();
  const HttpCreate = useHttp();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      device: null,
    },
  });

  const handleCreate = async (data: any) => {
    const {
      name,
      description,
      device: { _id },
    } = data;
    await HttpCreate.newRequest(`/installations`, {
      method: "POST",
      body: {
        locationId,
        deviceId: _id,
        name,
        description,
        period: 10,
        type: "sensor",
      },
      successMessage: "installationSucessfullyCreated",
    });
    getData();
    getAvailableDevices();
  };

  return (
    <form onSubmit={handleSubmit(handleCreate)}>
      {formFields.map(({ name, rules, props }) => (
        <Box key={name} pb="24px">
          <Controller
            name={name as any}
            rules={rules}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                {...props}
                fullWidth
                label={t(name)}
                error={!!errors[name]}
                helperText={t(errors[name]?.message as string, {
                  value: errors[name],
                })}
              />
            )}
          />
        </Box>
      ))}
      <Controller
        name="device"
        rules={{
          required: "requiredField",
        }}
        control={control}
        render={({ field }) => (
          <Autocomplete
            {...field}
            fullWidth
            disablePortal
            id="combo-box-demo"
            onChange={(e, data) => field.onChange(data ?? null)}
            options={devices}
            sx={{ mb: "50px" }}
            getOptionLabel={(option: any) =>
              `${t(option.type)} - ${option._id}`
            }
            renderInput={(params: any) => (
              <TextField
                {...params}
                label={t("sensor")}
                error={!!errors.device}
                helperText={t(errors.device?.message as string)}
              />
            )}
            noOptionsText={t("noResultsFound")}
          />
        )}
      />

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" sx={{ mt: "20px" }} type="submit">
          {HttpCreate.loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            t("save")
          )}
        </Button>
      </Box>
    </form>
  );
};

export default CreationForm;
