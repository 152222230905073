import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingOverlayer from "../components/Loading";
import { useHttp } from "../hooks/useHttpClient";
import { useLogOut } from "../hooks/useLogOut";

type User = {
  username: string;
};

interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  accessToken: string;
  assignedRoles: string[];
  dispatch?: React.Dispatch<Action>
}

const initialState = {
  isAuthenticated: false,
  user: null,
  accessToken: "",
  assignedRoles: [],
  dispatch: () => null
};

export interface Action {
  type: string;
  payload?: AuthState;
}


const AuthContext = createContext<AuthState>(initialState);

const authReducer = (state: AuthState, action: Action): AuthState => {
  const { type, payload } = action;
  if (type === "initial") {
    return {...state, ...payload };
  } else if (type === "logOut") {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      accessToken: "",
      assignedRoles: [],
    };
  }
  return state;
};

interface Props {
  children: React.ReactElement;
}

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const value = useMemo(() => ({ ...state, dispatch }), [state]);
  const initialRoute = useRef("/");

  const HttpSession = useHttp();

  const [initialCheck, setInitialCheck] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const accessToken = localStorage.getItem("accessToken") ?? "";

  useEffect(() => {
    if (HttpSession.item.user) {
      dispatch({
        type: "initial",
        payload: {
          isAuthenticated: true,
          accessToken,
          user: HttpSession.item?.user,
          assignedRoles: HttpSession.item?.user?.assignedRoles,
        },
      });
    }
    setInitialCheck(true);
  }, [HttpSession.item]);

  console.log("initialCheck: ", initialCheck);

  useEffect(() => {
    initialRoute.current = location.pathname;
    (async (): Promise<any> => {
      if (!accessToken) {
        localStorage.setItem("initialURL", location.pathname);
        navigate("/login");
        setInitialCheck(true);
      } else {
        await HttpSession.newRequest(`/auth/session`);
        if (location.pathname === "/login") {
          navigate("/locations");
        }
      }
    })();
  }, []);

  if (!initialCheck) {
    return <LoadingOverlayer />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === null) {
    throw new Error("useCategories must be used within a Category");
  }

  return context;
};
