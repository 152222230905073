import React, { FC, useEffect, useState } from "react";
import "../App.css";
import "react-circular-progressbar/dist/styles.css";
import Box from "@mui/material/Box";
import { getDisplayTime } from "../utils/getDisplayDate";
import { useParams } from "react-router-dom";
import LineChart from "../components/charts/LineChart";
import { getChartDatasets } from "../components/charts/getChartData";
import ValuePreviev from "../components/ValuePreview";
import Card from "@mui/material/Card";
import { baseURL } from "../hooks/useHttpClient";

interface LiveDataScreenProps {
  deviceType: string;
}

const LiveDataScreen: FC<LiveDataScreenProps> = ({ deviceType }) => {
  const { installationId } = useParams();

  const [labels, setLabels] = useState<string[]>([]);
  const [values1, setValues1] = useState<number[]>([]);
  const [values2, setValues2] = useState<number[]>([]);
  const [data, setData] = useState<{ value1: number; value2: number }>();

  useEffect(() => {
    if (data) {
      const label = getDisplayTime();
      if (labels.length < 9) {
        setLabels((prev) => [...prev, label]);
        setValues1((prev) => [...prev, data.value1]);
        setValues2((prev) => [...prev, data.value2]);
      } else {
        setLabels((prev) => {
          const a = prev.slice(1);
          console.log(a, values1.length);
          return [...a, label];
        });
        setValues1((prev) => {
          const a = prev.slice(1);
          return [...a, data.value1];
        });
        setValues2((prev) => {
          const a = prev.slice(1);
          return [...a, data.value2];
        });
      }
    }
  }, [data]);

  const chartConfig = {
    labels,
    datasets: getChartDatasets(deviceType, [values1, values2]),
  };

  const handleData = (
    values: { created: string; value1: number; value2: number }[]
  ) => {
    const labels = values.map((item) => getDisplayTime(item.created));
    const values1 = values.map((item) => +item.value1.toFixed(1));
    const values2 = values.map((item) => +item.value2.toFixed(1));
    setLabels(labels);
    setValues1(values1);
    setValues2(values2);
  };

  useEffect(() => {
    const eventSource = new EventSource(
      `${baseURL}/readings/live/${installationId}`
    );
    eventSource.onmessage = (event) => {
      const eventData = JSON.parse(event.data);
      if (eventData.message === "INITIAL_DATA") {
        handleData(eventData.data);
      } else if (eventData.message === "LIVE_UPDATE") {
        setData(eventData.data);
      }
    };

    eventSource.onerror = () => {
      console.log("Event Source CLOSED");
    };

    return () => {
      eventSource.close();
    };
  }, []);

  let latestValues = [values1[values1.length - 1], values2[values2.length - 1]];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "70vh",
      }}
    >
      {chartConfig.datasets.map((item, i) => (
        <Card
          elevation={3}
          key={item.label}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: "20px 30px",
            height: "380px",
            mb: "25px",
            width: "60%",
            minWidth: "840px"
            // height: `100%`,
          }}
        >
          <LineChart
            data={{ ...chartConfig, datasets: [{ ...item }] }}
            loading={false}
            suggestedMax={Math.max(...item.data) + 2}
            suggestedMin={Math.min(...item.data) - 2}
          />
          <ValuePreviev
            label={item.label}
            type={item.label}
            value={latestValues[i]}
          />
        </Card>
      ))}
    </Box>
  );
};

export default LiveDataScreen;
