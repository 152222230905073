import React, { FC, ReactElement } from "react";
import "react-circular-progressbar/dist/styles.css";
import Box from "@mui/material/Box";
import PeriodicSensorReportWrapper from "./PeriodicDevicesReport";
import NonPeriodicSensorReportWrapper from "./NonPeriodicDeviceReport";

interface ReportProps {
  deviceType: string;
}

const periodicDevicesType = ["temp&humid", "temp", "humid", "pressure"];

const Report: FC<ReportProps> = ({ deviceType }): ReactElement => {
  const periodicSensor = periodicDevicesType.includes(deviceType);

  return (
    <Box>
      {periodicSensor ? (
        <PeriodicSensorReportWrapper deviceType={deviceType} />
      ) : (
        <NonPeriodicSensorReportWrapper deviceType={deviceType} />
      )}
    </Box>
  );
};

export default Report;
