import { SxProps, Theme, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { FC, ReactElement } from "react";
import Zoom from "@mui/material/Zoom";
import { useTranslation } from "react-i18next";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface TooltipIconProps {
  title: string;
  icon?: "info";
  sx?: SxProps<Theme>
}

const icons: Record<string, React.ReactElement> = {
  info: <InfoOutlinedIcon color="info" />,
};

const TooltipIcon: FC<TooltipIconProps> = ({
  title,
  icon = "info",
  sx
}): ReactElement => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={t(title)}
      arrow
      TransitionComponent={Zoom}
      PopperProps={{
        sx: { zIndex: 10000 },
      }}
      sx={sx}
    >
      {icons[icon]}
    </Tooltip>
  );
};

export default TooltipIcon;
