import { t } from "i18next";

const alertConditions: Record<string, string[]> = {
  "temp&humid": [
    "tempGreaterThan",
    "tempLessThan",
    "humidGreaterThan",
    "humidLessThan",
  ],
  humid: ["humidGreaterThan", "humidLessThan"],
  pressure: ["pressureGreaterThan", "pressureLessThan"],
  "open&close": ["whenOpen"],
  switch: ["whenOn", "whenOff"],
};

export const typeValues: Record<string, string[]> = {
  "temp&humid": ["°C", "°C", "%", "%"],
  humid: ["%", "%"],
  pressure: ["mbar", "mbar"],
  "open&close": [""],
  switch: ["", ""],
};

export const getSelectOptions = (type: string): string[] => {
  return alertConditions[type].map((item) => t(item));
};

export const getAlertDisplayType = (
  type: string,
  state: number,
  value: number
) => {
  const index = state - 1;
  const condition = `${t(alertConditions[type][index])}`;
  const unit = typeValues[type][index];
  if (!unit) {
    return condition;
  }
  return `${condition} ${value}${unit}`;
};
