import { MenuItem, TextField, Theme, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

interface LanguageSelectProps {}

const data = [
  {
    value: "sr",
    imgCode: "RS",
    label: "SR",
  },
  // {
  //   value: "en",
  //   imgCode: "US",
  //   label: "EN",
  // },
  {
    value: "en",
    imgCode: "GB",
    label: "EN",
  },
  {
    value: "de",
    imgCode: "DE",
    label: "DE",
  },
  {
    value: "fr",
    imgCode: "FR",
    label: "FR",
  },
];

const LanguageSelect: FC<LanguageSelectProps> = (): ReactElement => {
  const { t, i18n } = useTranslation();

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    i18n.changeLanguage(e.target.value);
    localStorage.setItem("systemLanguage", e.target.value);
  };

  return (
      <TextField
        select
        value={i18n.language}
        sx={(theme: Theme) => ({
          width: "110px",
          py:"0px",
          "& >div > fieldset": {
            border: "none",
            // borderColor: theme.palette.primary.main
          },
      
        })}
        variant="outlined"
        
        onChange={(value) => handleChange(value)}
      >
        {data.map(({ imgCode, value, label }) => (
          <MenuItem value={value} key={label}>
            <Box display="flex" alignItems="center">
              <img
                src={`https://flagsapi.com/${imgCode}/shiny/64.png`}
                width={32}
                height={32}
                alt="img"
              />
              <Typography ml="8px" variant="h6">
                {t(label)}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </TextField>
  );
};

export default LanguageSelect;
