import { FC, ReactElement } from "react";
import { useAuth } from "../AuthProvider";
import { Navigate } from "react-router-dom";
import LoadingOverlayer from "../../components/Loading";

interface RouteGuardProps {
  children: ReactElement;
  authorizedRoles?: string[];
}

const RouteGuard: FC<RouteGuardProps> = ({
  children,
  authorizedRoles,
}): ReactElement => {
  const { assignedRoles } = useAuth();
  if (assignedRoles) {
    const accessCheck = authorizedRoles?.some((role) =>
      assignedRoles.includes(role)
    );
    if (!accessCheck) {
      return <Navigate to="/" />;
    }
    return children;
  }
  return <LoadingOverlayer />;
};

export default RouteGuard;
