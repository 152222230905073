import {
  Button,
  CircularProgress,
  InputAdornment,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import React, { FC, ReactElement, useState } from "react";
import { getPeriod } from "../utils/getPeriod";
import { useTranslation } from "react-i18next";
import { useHttp } from "../hooks/useHttpClient";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

interface SettingsProps {
  value: number;
}

const Settings: FC<SettingsProps> = ({ value }): ReactElement => {
  const { t } = useTranslation();
  const { installationId } = useParams();
  const [periodValue, setPeriodValue] = useState(getPeriod(+value));

  const HttpPeriodUpdate = useHttp();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      period: null,
    },
  });

  const handleUpdate = async (values: any) => {
    const { period } = values;
    await HttpPeriodUpdate.newRequest(`/installations/periodUpdate/${installationId}`, {
      method: "PATCH",
      body: {
        period,
      },
      successMessage: "valueSucessfullyUpdated",
    });
    setPeriodValue(getPeriod(period));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          mt: "10px",
        }}
      >
        {t("currentPeriodValue")} <strong>{periodValue}</strong>.
      </Typography>
      <form onSubmit={handleSubmit(handleUpdate)} style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
      }}>
        <Controller
          name="period"
          rules={{
            required: "requiredField",
            min: { value: 2, message: t("minPeriodValue", { value: 2 })  }
          }}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="number"
              sx={{ mb: "28px", mt: "36px", width: "300px" }}
              label={t("period")}
              error={!!errors.period}
              helperText={t(errors.period?.message as string)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">sec</InputAdornment>
                ),
              }}
            />
          )}
        />

        <Button variant="contained" type="submit">
          {HttpPeriodUpdate.loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            t("save")
          )}
        </Button>
      </form>
    </Box>
  );
};

export default Settings;
