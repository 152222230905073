import React, { FC, ReactElement } from "react";
import Box from "@mui/material/Box";
import { Controller, useForm } from "react-hook-form";
import { useHttp } from "../../hooks/useHttpClient";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";

interface AssignDeviceFormProps {
  handleDialog: () => void;
  getData: () => Promise<void>;
}

const AssignDeviceForm: FC<AssignDeviceFormProps> = ({
  getData,
  handleDialog,
}): ReactElement => {
  const HttpAssign = useHttp();
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      deviceID: "",
      identificationCode: "",
    },
  });

  const formFields: {
    name: "deviceID" | "identificationCode";
    rules: Record<any, any>;
    props: any;
  }[] = [
    {
      name: "deviceID",
      rules: {
        required: "requiredField",
        minLength: { value: 4, message: t("minLength", { value: 4 }) },
        maxLength: { value: 5, message: t("maxLength", { value: 5 }) },
      },
      props: {
        type: "number",
      },
    },
    {
      name: "identificationCode",
      rules: {
        required: "requiredField",
        minLength: { value: 8, message: t("charactersRequired", { value: 8 }) },
        maxLength: { value: 8, message: t("charactersRequired", { value: 8 }) },
      },
      props: {},
    },
  ];

  const handleAssignment = async (values: any) => {
    const { deviceID, identificationCode } = values;
    try {
      await HttpAssign.newRequest(`/devices/assignDevice`, {
        method: "PATCH",
        body: {
          deviceID,
          identificationCode,
        },
        successMessage: "deviceSucessfullyUpdated",
        throwError: true,
      });
      handleDialog();
      getData();
    } catch {}
  };
  return (
    <Box sx={{ width: "400px", p: "30px" }}>
      {" "}
      <form onSubmit={handleSubmit(handleAssignment)}>
        {formFields.map(({ name, rules, props }) => (
          <Box key={name} pb="24px">
            <Controller
              name={name as any}
              rules={rules}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  {...props}
                  fullWidth
                  onChange={(e) => field.onChange(e.target.value.toUpperCase())}
                  label={t(name)}
                  error={!!errors[name]}
                  helperText={t(errors[name]?.message as string, {
                    value: errors[name],
                  })}
                />
              )}
            />
          </Box>
        ))}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" sx={{ mt: "20px" }} type="submit">
            {HttpAssign.loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t("save")
            )}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AssignDeviceForm;
