import Box from "@mui/material/Box";
import React, { FC, ReactElement, useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { useHttp } from "../../hooks/useHttpClient";
import { useParams } from "react-router-dom";
import TabsWrapper from "../../components/TabsWrapper";
import SensorInstallationScreen from "./sensor/SensorScreen";
import SwitchInstallationScreen from "./switch/SwitchScreen";

type InstallationSelectedType = "sensor" | "switch" | "combo";
const tabs = ["sensors", "switches", "combo"];

interface ScreenWrapperProps {}

const InstallationsScreenWrapper: FC<ScreenWrapperProps> = (): ReactElement => {
  const [installationSelectedType, setInstallationSelectedType] =
    useState<InstallationSelectedType>("sensor");

  const HttpGetLocation = useHttp();
  const { locationId } = useParams();

  const handleTabChange = (index: number) => {
    const installationAvailableTypes: InstallationSelectedType[] = [
      "sensor",
      "switch",
      "combo",
    ];
    setInstallationSelectedType(installationAvailableTypes[index] ?? "sensor");
  };

  const tabsScreens = [
    {
      condition: true,
      screen: <SensorInstallationScreen />,
    },
    {
      condition: true,
      screen: <SwitchInstallationScreen />,
    },
  ];

  useEffect(() => {
    HttpGetLocation.newRequest(`/locations/${locationId}`);
  }, []);

  return (
    <Box>
      <PageHeader
        data={[
          { label: "locations", path: "/locations" },
          {
            label: HttpGetLocation.item?.name,
            path: `/locations/${locationId}`,
          },
        ]}
      />
      <TabsWrapper
        tabs={tabs}
        screens={tabsScreens}
        handleTabChange={handleTabChange}
      />
    </Box>
  );
};

export default InstallationsScreenWrapper;
